import React, { memo } from 'react'

const PassIcon = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <rect
        x="2.3999"
        y="6.5"
        width="11.2"
        height="8"
        rx="0.8"
        stroke="#8C8D96"
        strokeWidth="1.2"
      />
      <path
        d="M11.1998 6.5C11.1998 4.29086 10.2089 2.5 7.9998 2.5C5.79067 2.5 4.7998 4.29086 4.7998 6.5"
        stroke="#8C8D96"
        strokeWidth="1.2"
      />
      <rect x="4.7998" y="9.7" width="1.6" height="1.6" fill="#8C8D96" />
      <rect x="7.2002" y="9.7" width="1.6" height="1.6" fill="#8C8D96" />
      <rect x="9.6001" y="9.7" width="1.6" height="1.6" fill="#8C8D96" />
    </svg>
  )
})

export default PassIcon
