import React, { memo, useState } from 'react'
import ShowPassIcon from '@/assets/svg/ShowPassIcon'
import Input from '@/ui/Input'
import Consent from '../Consent'
import classNames from 'classnames'
import { Button } from 'antd'
import CSS from './index.module.scss'
import CodeIcon from '@/assets/svg/CodeIcon'

const PassLogin = memo((props: any = {}) => {
  const { loginFormData } = props
  const [toggle, setToggle] = useState(false)
  return (
    <div>
      <Input
        value={loginFormData.mobile}
        type="number"
        maxLength={11}
        placeholder="请输入手机号"
        onChange={(e: any) => props.onChange(e.target.value, 'mobile')}
      />
      <Input
        className="mt-4"
        type={toggle ? 'text' : 'password'}
        placeholder="请输入密码"
        maxLength={18}
        value={props.loginFormData.password}
        onChange={(e: any) => props.onChange?.(e.target.value, 'password')}
        rightChild={
          <div className="cursor-pointer flex items-center" onClick={() => setToggle(!toggle)}>
            <ShowPassIcon toggle={toggle} />
          </div>
        }
      />
      <Consent isAgree={props.isAgree} setIsAgree={props.setIsAgree} />
      <div className={classNames([CSS.btn_box, 'mt-4'])}>
        <Button
          className={classNames([CSS.default_btn, 'aiq-default-btn'])}
          onClick={() => props.setType(-1)}
          disabled={props.loading}
        >
          注册
        </Button>
        <Button
          className="aiq-btn-primary"
          type="primary"
          onClick={props.onSubmit}
          loading={props.loading}
        >
          登录
        </Button>
      </div>
      <div className={classNames([CSS.rests, 'flex items-center justify-between mt-6'])}>
        <div className={CSS.btn1} onClick={() => props.setType(0)}>
          <CodeIcon />
          <span>验证码登录</span>
        </div>
        <div className={CSS.forgot_pass} onClick={() => props.setType(2)}>
          忘记密码
        </div>
      </div>
    </div>
  )
})

export default PassLogin
