import React, { memo, useState } from 'react'
import { Button, Modal } from 'antd'
import CSS from './index.module.scss'
import useMainStore from '@/store/main'
import Input from '@/ui/Input'
import useUserStore from '@/store/user'
import { useRequest } from '@/hooks/useRequest'
import * as UserAPI from '@/api/modules/user'
import antdUI from '@/antd-ui'
import { verifyEmail } from '@/utils'
import { messageTool } from '@/utils/tool'

const EmailModal = memo(() => {
  const { changeSwitchHandle, emailModalSwitch } = useMainStore(state => ({
    changeSwitchHandle: state.changeSwitchHandle,
    emailModalSwitch: state.emailModalSwitch,
  }))
  const userInfo = useUserStore(state => state.userInfo)
  const fetchUserInfo = useUserStore(state => state.fetchUserInfo)
  const [email, setEmail] = useState(userInfo?.email ?? '')
  const bindEmailReq = useRequest(UserAPI.bindEmail)

  const onOkHandle = async () => {
    if (!verifyEmail(email)) {
      return messageTool('请输入正确的邮箱号!', 'error')
    }
    if (email === userInfo?.email) {
      return changeSwitchHandle('emailModalSwitch', false)
    }
    const [err, res] = await bindEmailReq.run({ email })
    if (err) return
    antdUI.messageApi.open({
      type: 'success',
      content: '设置成功',
    })
    fetchUserInfo()
    changeSwitchHandle('emailModalSwitch', false)
  }
  return (
    <Modal
      className={CSS.login_modal}
      width={320}
      title="设置默认邮箱"
      open={emailModalSwitch}
      onCancel={() => changeSwitchHandle('emailModalSwitch', false)}
      footer={
        <div className={CSS.btn_box}>
          <Button
            type="primary"
            className="aiq-btn-primary"
            onClick={onOkHandle}
            loading={bindEmailReq.loading}
          >
            保存
          </Button>
        </div>
      }
    >
      <div className={CSS.desc}>生成记录可一键发送到此邮箱</div>
      <Input
        className="mt-4"
        placeholder="请输入邮箱号"
        inputStyle={{ textAlign: 'center' }}
        value={email}
        onChange={(e: any) => setEmail(e.target.value)}
      />
    </Modal>
  )
})

export default EmailModal
