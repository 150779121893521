import React, { memo, useEffect, useRef, useState } from 'react'
import CSS from './center.module.scss'
import WorkContent from './cpns/WorkContent'
import Plan from './cpns/Plan'
import Generate from './cpns/Generate'
import ContentSection from './cpns/ContentSection'
import * as ChatAPI from '@/api/modules/chat'
import analysisStreamTool from '@/utils/analysisStream'
import useUserStore from '@/store/user'
import useMainStore from '@/store/main'
import antdUI from '@/antd-ui'
import useChatStore from '@/store/chat'
import DoneBtn from './cpns/DoneBtn'
import { messageTool } from '@/utils/tool'
import useListStore from '@/store/list'

const Center = memo(() => {
  const PlanRef = useRef<any>(null)
  const WorkContentRef = useRef<any>(null)
  const isLogin = useUserStore(state => state.isLogin)
  const { templateModalInfo, changeTemplateModalInfo, changeSwitchHandle } = useMainStore(
    state => ({
      changeSwitchHandle: state.changeSwitchHandle,
      changeTemplateModalInfo: state.changeTemplateModalInfo,
      templateModalInfo: state.templateModalInfo,
    })
  )
  const getHistoryList = useListStore(state => state.getHistoryList)
  const historyListInfo = useListStore(state => state.historyListInfo)
  const {
    isInput,
    content,
    reportId,
    changeContent,
    changeIsInput,
    changeReportId,
    changeIsCollect,
    changeType,
  } = useChatStore()

  const contentRef = useRef('')
  const reportIdRef = useRef<number | null>(null)
  const abortControllerRef = useRef<AbortController | null>(null)

  const fetchAndVerify = () => {
    const data = { ...PlanRef.current.getData(), ...WorkContentRef.current.getData() }
    if (verifyState(data)) return data
  }

  const verifyState = (data: any) => {
    if (!isLogin()) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请先登录~',
      })
      changeSwitchHandle('loginSwitch', true)
      return
    }
    if (!data.workContent.trim()) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '工作内容不能为空',
      })
      return
    }
    return true
  }

  const startGenerate = async () => {
    // console.log('start')
    const data = fetchAndVerify()
    if (!data) return
    // 初始化start
    changeIsInput(true)
    changeContent('')
    changeReportId(null)
    changeIsCollect(false)
    changeType('')
    contentRef.current = ''
    reportIdRef.current = null
    // 初始化end
    abortControllerRef.current = new AbortController()
    try {
      const resp = await ChatAPI.fetchPostStream({
        data,
        signal: abortControllerRef.current.signal,
        onDownloadProgress: (str: string) => {
          // console.log('str: ', str)
          analysisStreamTool.analysisData2(str, (msgItem: any) => {
            // console.log('msgItem: ', msgItem)
            // 判断是否结束
            if (msgItem === '[DONE]') {
              if (historyListInfo.page === 1) {
                getHistoryList()
              }
              return
            }

            // 把报告id保存下来
            if (reportIdRef.current === null && typeof msgItem.request_id === 'number') {
              reportIdRef.current = msgItem.request_id
              changeReportId(reportIdRef.current as number)
            }

            const msg = msgItem?.choices?.[0]?.delta?.content
            if (msg !== void 0) {
              contentRef.current += msg
              changeContent(contentRef.current)
            }
          })
        },
      })

      console.log(resp)
    } catch (error) {
      console.log('error: ', error)
    }
    changeIsInput(false)
  }

  const stopGenerate = () => {
    console.log('stop')
    abortControllerRef.current?.abort()
  }

  const onAddDrafts = async () => {
    const data = fetchAndVerify()
    if (!data) return
    changeTemplateModalInfo({
      ...templateModalInfo,
      open: true,
      data: data,
      title: '添加模板',
    })
  }
  const onUpdateDrafts = async () => {
    const data = fetchAndVerify()
    if (!data) return
    if (templateModalInfo.id === null) {
      return messageTool('检测到没有模板id无法保存', 'error')
    }
    changeTemplateModalInfo({
      ...templateModalInfo,
      open: true,
      data: data,
      title: '更新模板',
    })
  }
  return (
    <div className={CSS.wrapper}>
      <div className={CSS.content}>
        <WorkContent
          ref={WorkContentRef}
          onAddDrafts={onAddDrafts}
          onUpdateDrafts={onUpdateDrafts}
        />
        <Plan ref={PlanRef} />
        <Generate onStart={startGenerate} onStop={stopGenerate} />
        <ContentSection content={content} />
        {content && !isInput && (
          <DoneBtn content={content} reportId={reportId} onStart={startGenerate} />
        )}
      </div>
    </div>
  )
})

export default Center
