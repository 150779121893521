import React, { memo, useEffect, useRef, useState } from 'react'
import CSS from './index.module.scss'
import classNames from 'classnames'

const Tabs = memo((props: any = {}) => {
  const [lineX, setLineX] = useState(0)
  const lineRef = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const conChild = contentRef.current?.children
    if (conChild) {
      const currentItem = Array.from(conChild)[props.activeKey] as HTMLDivElement
      const lineEl = lineRef.current as HTMLDivElement
      setLineX(currentItem.offsetLeft + currentItem.offsetWidth * 0.5 - lineEl.offsetWidth * 0.5)
    }
  }, [props.activeKey])

  return (
    <div className={CSS.tabs_box}>
      <div className={CSS.tab_content} ref={contentRef}>
        {props.list.map((v: any, i: number) => (
          <div
            className={classNames([CSS.tab_it, props.activeKey === i && CSS.activate])}
            key={v.key}
            onClick={() => props.onChange({ item: v, index: i })}
          >
            <span className={CSS.tab_it_text}>{v.label}</span>
          </div>
        ))}
      </div>
      <div className={CSS.line} ref={lineRef} style={{ transform: `translateX(${lineX}px)` }}></div>
    </div>
  )
})

export default Tabs
