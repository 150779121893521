import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import * as UserAPI from '@/api/modules/user'
import antdUI from '@/antd-ui'
import cache from '@/utils/cache'

interface IState {
  token: string
  userInfo: {
    name: string
    career: string
    career_age: string
    responsibility: string
    email: string
    career_num: number
    num: any
    is_real_auth: number
    real_auth_name: string
    real_auth_num: string
    username: string
  } | null
}

const state: IState = {
  token: '',
  userInfo: null,
}

const store = (set: any, get: any) => ({
  ...state,
  setToken(token: string) {
    set(() => ({
      token,
    }))
  },
  setUserInfo(userInfo: string) {
    set(() => ({
      userInfo,
    }))
  },
  isLogin() {
    const { token, userInfo } = get()
    return token && userInfo
  },
  async fetchUserInfo() {
    const { token } = get()
    try {
      if (!token) {
        return
      }
      const res: any = await UserAPI.getUserInfo()

      // if (res.code == 401) {
      //   antdUI.messageApi.open({
      //     type: 'error',
      //     content: '登录过期，请重新登录',
      //   })
      //   cache.clearCache()
      //   window.location.reload()
      //   return
      // }
      if (res.code != 200) {
        antdUI.messageApi.open({
          type: 'error',
          content: res.msg || '系统异常',
        })
        return
      }
      set({
        userInfo: res.data,
      })
    } catch (error) {
      console.log('error: ', error)
    }
  },
})

function partialize(state: any) {
  // 过滤字段
  const filterArr = ['loginSwitch']
  return Object.fromEntries(Object.entries(state).filter(([key]) => !filterArr.includes(key)))
}

const useUserStore = create(persist(store, { name: 'user-state', partialize }))

export default useUserStore
