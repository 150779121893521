import React, { memo } from 'react'
import CSS from './index.module.scss'
import classNames from 'classnames'
import ArrowsRight1 from '@/assets/svg/ArrowsRight1'

const Cell = memo((props: any = {}) => {
  const { leftIcon = null, label = '', rightContent = '', showRightArrows = true } = props
  return (
    <div
      className={classNames([CSS.cell, 'flex items-center justify-between cursor-pointer'])}
      onClick={props.onClick}
    >
      <div className={classNames([CSS.cell_left, 'flex items-center'])}>
        <div className="flex items-center mr-2">{leftIcon}</div>
        <div className={CSS.cell_label}>{label}</div>
      </div>
      <div className={classNames([CSS.cell_right, 'flex items-center'])}>
        <div className="mr-2">{rightContent}</div>
        {showRightArrows && <ArrowsRight1 />}
      </div>
    </div>
  )
})

export default Cell
