import React, { useMemo } from 'react'
import { Routes, Route, useRoutes } from 'react-router-dom'
import { defaultRouter } from './defaultRouter'

const allRoutes: any[] = []
// const routeFiles = require.context(`./modules`, true, /.tsx/)
// routeFiles.keys().forEach((key: string) => {
//   const routes = require(`./modules${key.split('.')[1]}`)
//   allRoutes.push(routes.default)
// })

// function filterMenu(useMenus: any[] = [], newRoutes: any[]) {
//   for (const route of useMenus) {
//     if (route.children?.length) {
//       filterMenu(route.children, newRoutes)
//     } else {
//       const current = allRoutes.find(r => r.path === route.path)
//       if (current) {
//         newRoutes.push(current)
//       }
//     }
//   }
// }

// function menusToRoutes(menus = []) {
//   const newRoutes: any = []
//   filterMenu(menus, newRoutes)
//   return newRoutes
// }

export const RouterComponent = () => {
  // console.log('RouterComponent')
  // const menus = useMenuStore(state => state.menus)

  // const newRouter = useMemo(() => {
  //   console.log('menus2: ', menus)
  //   if (menus.length) {
  //     const newRoutes = menusToRoutes(menus)
  //     return defaultRouter.concat(newRoutes)
  //   }
  //   return defaultRouter
  // }, [menus])

  return useRoutes(defaultRouter)
}
