import { request } from '..'
import type { IDataType } from '../type'

enum API {
  getCareerList = '/report/user/getCareerList',
  getUserInfo = '/report/user/getUserInfo',
  addCareer = '/report/user/addCareer',
  bindEmail = '/report/user/bindEmail',
  getList = '/report/user/getList',
  collection = '/report/user/collection',
  deletRreport = '/report/user/delete',
  editReport = '/report/user/editReport',
  sendEmail = '/report/user/sendEmail',
  getMyCareers = '/report/user/getMyCareers',
  delCareer = '/report/user/delCareer',
  editCareer = '/report/user/editCareer',
  realAuth = '/report/user/realAuth',
  feedback = '/report/user/feedback',
  register = '/report/user/register',
  mobilelogin = '/report/user/mobilelogin',
  login = '/report/user/login',
  forgetpwd = '/report/user/forgetpwd',
}

/**
 * 忘记密码
 */
export function forgetpwd(data = {}) {
  return request.post<IDataType>({
    url: API.forgetpwd,
    data,
  })
}

/**
 * 用户注册
 */
export function register(data = {}) {
  return request.post<IDataType>({
    url: API.register,
    data,
  })
}

/**
 * 验证码登录
 */
export function mobilelogin(data = {}) {
  return request.post<IDataType>({
    url: API.mobilelogin,
    data,
  })
}

/**
 * 密码登录
 */
export function login(data = {}) {
  return request.post<IDataType>({
    url: API.login,
    data,
  })
}

/**
 * 用户协议
 */
export function getUserAgree(data = {}) {
  return request.post<IDataType>({
    url: '/report/index/userAgree',
    data,
  })
}

/**
 * 隐私协议
 */
export function getPrivacyPolicy(data = {}) {
  return request.post<IDataType>({
    url: '/report/index/privacyPolicy',
    data,
  })
}

/**
 * 获取职业列表
 */
export function getCareerList() {
  return request.post<IDataType>({
    url: API.getCareerList,
  })
}

/**
 * 获取用户信息
 */
export function getUserInfo() {
  return request.post<IDataType>({
    url: API.getUserInfo,
  })
}

/**
 * 收集用户信息
 */
export function addCareer(data = {}) {
  return request.post<IDataType>({
    url: API.addCareer,
    data,
  })
}

/**
 * 绑定邮箱
 */
export function bindEmail(data = {}) {
  return request.post<IDataType>({
    url: API.bindEmail,
    data,
  })
}

/**
 * 获取历史、收藏列表接口
 */
export function getList(data = {}) {
  return request.post<IDataType>({
    url: API.getList,
    data,
  })
}

/**
 * 收藏报告接口
 */
export function collection(ids: number[]) {
  return request.post<IDataType>({
    url: API.collection,
    data: {
      ids,
    },
  })
}

/**
 * 报告 单个、批量删除
 */
export function deletRreport(data = {}) {
  return request.post<IDataType>({
    url: API.deletRreport,
    data,
  })
}

/**
 * 报告 单个、批量删除
 */
export function editReport(data = {}) {
  return request.post<IDataType>({
    url: API.editReport,
    data,
  })
}

/**
 * 邮件发送接口
 */
export function sendEmail(data = {}) {
  return request.post<IDataType>({
    url: API.sendEmail,
    data,
  })
}

/**
 * 我的职业列表
 */
export function getMyCareersList(data = {}) {
  return request.post<IDataType>({
    url: API.getMyCareers,
    data,
  })
}

/**
 * 删除用户职业、工龄、职位接口
 */
export function delCareer(id: number) {
  return request.post<IDataType>({
    url: API.delCareer,
    data: {
      id,
    },
  })
}

/**
 * 删除用户职业、工龄、职位接口
 */
export function editCareer(data = {}) {
  return request.post<IDataType>({
    url: API.editCareer,
    data,
  })
}

/**
 * 实名验证
 */
export function realAuth(data = {}) {
  return request.post<IDataType>({
    url: API.realAuth,
    data,
  })
}

/**
 * 问题反馈
 */
export function feedback(data = {}) {
  return request.post<IDataType>({
    url: API.feedback,
    data,
  })
}
