import React, { memo, useState } from 'react'
import { Button } from 'antd'
import classNames from 'classnames'
import CSS from './index.module.scss'
import GetCaptcha from '../GetCaptcha'
import Input from '@/ui/Input'
import ShowPassIcon from '@/assets/svg/ShowPassIcon'

const ForgotPass = memo((props: any = {}) => {
  const { forgotPassForm } = props
  const [toggle, setToggle] = useState(false)
  const [toggle2, setToggle2] = useState(false)
  return (
    <div>
      <GetCaptcha codeEvent="forgetpwd" form={forgotPassForm} onChange={props.onChange} mt="mt-3" />
      <Input
        className="mt-3"
        type={toggle ? 'text' : 'password'}
        placeholder="请输入密码"
        maxLength={18}
        value={forgotPassForm.newpassword}
        onChange={(e: any) => props.onChange?.(e.target.value, 'newpassword')}
        rightChild={
          <div className="cursor-pointer flex items-center" onClick={() => setToggle(!toggle)}>
            <ShowPassIcon toggle={toggle} />
          </div>
        }
      />
      <Input
        className="mt-3"
        type={toggle2 ? 'text' : 'password'}
        placeholder="请再次输入密码"
        maxLength={18}
        value={forgotPassForm.confirm_password}
        onChange={(e: any) => props.onChange?.(e.target.value, 'confirm_password')}
        rightChild={
          <div className="cursor-pointer flex items-center" onClick={() => setToggle2(!toggle2)}>
            <ShowPassIcon toggle={toggle2} />
          </div>
        }
      />
      <div className={classNames([CSS.btn_box])}>
        <Button
          className="aiq-btn-primary"
          type="primary"
          onClick={props.onSubmit}
          loading={props.loading}
        >
          重置密码
        </Button>
      </div>
    </div>
  )
})

export default ForgotPass
