import React, { memo } from 'react'

const MsgIcon = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M1.33337 13H14.6667V8V3H8.00004H1.33337V8V13Z"
        stroke="#404040"
        strokeWidth="1.33333"
        strokeLinejoin="round"
      />
      <path
        d="M1.33337 3L8.00004 8L14.6667 3"
        stroke="#404040"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00004 3H1.33337V8"
        stroke="#404040"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 8V3H8"
        stroke="#404040"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})

export default MsgIcon
