import React, { memo } from 'react'

const EmptyIcon2 = memo(() => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="139"
      height="143"
      viewBox="0 0 139 143"
      fill="none"
    >
      <path
        d="M71.0641 127C103.84 127 130.42 100.6 130.42 68C130.42 35.4 103.84 9 71.0641 9C38.2888 9 11.7087 35.4 11.7087 68C11.7087 100.6 38.2888 127 71.0641 127Z"
        fill="#EAEEF9"
      />
      <path
        d="M116.53 60.2999V96.4C116.53 103.9 110.434 110 102.84 110H39.4878C31.9934 110 25.898 104 25.7981 96.5C25.7981 96.4 25.7981 96.4 25.7981 96.3V60.2999C25.7981 60.2 25.7981 60.1999 25.7981 60.0999C25.7981 59.8999 25.7981 59.7 25.898 59.5C25.9979 59.2 26.0979 59 26.1978 58.7L43.185 26.2C43.7846 24.9 45.0836 24.2 46.4825 24.2H95.7455C97.1445 24.2 98.3436 24.9 99.0431 26.2L116.03 58.7C116.13 58.9 116.23 59.2 116.33 59.5C116.53 59.7 116.53 60 116.53 60.2999Z"
        fill="url(#paint0_linear_3191_4249)"
      />
      <g filter="url(#filter0_d_3191_4249)">
        <path
          d="M84.1543 60.6H58.4736C57.3744 60.6 56.4751 59.8 56.4751 58.6V23.5C56.4751 22.4 57.2745 21.5 58.4736 21.5H84.0544C85.1535 21.5 86.0529 22.3 86.0529 23.5V58.6C86.0529 59.7 85.2535 60.6 84.1543 60.6Z"
          fill="#F5F8FF"
        />
      </g>
      <g filter="url(#filter1_d_3191_4249)">
        <path
          d="M92.6477 68.9001H49.9798C48.5809 68.9001 47.4817 67.7001 47.4817 66.4001V32.3C47.4817 30.9 48.6808 29.8 49.9798 29.8H92.6477C94.0467 29.8 95.1459 31 95.1459 32.3V66.4001C95.0459 67.8001 94.0467 68.9001 92.6477 68.9001Z"
          fill="#F5F8FF"
        />
      </g>
      <g filter="url(#filter2_d_3191_4249)">
        <path
          d="M97.5443 78.6H45.1836C43.6848 78.6 42.3857 77.3 42.3857 75.9V42.3C42.3857 40.8 43.6848 39.6 45.1836 39.6H97.5443C99.0432 39.6 100.342 40.9 100.342 42.3V75.8C100.242 77.4 99.0432 78.6 97.5443 78.6Z"
          fill="#F5F8FF"
        />
      </g>
      <g filter="url(#filter3_d_3191_4249)">
        <path
          d="M102.84 88H39.8876C38.1889 88 36.8899 86.6 36.8899 85.1V52C36.8899 50.3 38.2888 49.1 39.8876 49.1H102.84C104.539 49.1 105.838 50.5 105.838 52V85C105.738 86.7 104.539 88 102.84 88Z"
          fill="#F5F8FF"
        />
      </g>
      <path
        d="M83.9545 62.2001H60.2723C59.6728 62.2001 59.0732 61.7 59.0732 61C59.0732 60.4 59.5729 59.8 60.2723 59.8H83.9545C84.5541 59.8 85.1536 60.3 85.1536 61C85.1536 61.7 84.654 62.2001 83.9545 62.2001Z"
        fill="#E6ECF5"
      />
      <path
        d="M77.0598 69.2001H47.0823C46.6826 69.2001 46.3828 68.9 46.3828 68.5C46.3828 68.1 46.6826 67.8 47.0823 67.8H76.9598C77.3595 67.8 77.6593 68.1 77.6593 68.5C77.8592 68.7 77.5594 69.2001 77.0598 69.2001Z"
        fill="#E6ECF5"
      />
      <path
        d="M96.7447 69.2001H81.656C81.2563 69.2001 80.9565 68.9 80.9565 68.5C80.9565 68.1 81.2563 67.8 81.656 67.8H96.6447C97.0444 67.8 97.3442 68.1 97.3442 68.5C97.5441 68.7 97.2443 69.2001 96.7447 69.2001Z"
        fill="#E6ECF5"
      />
      <g filter="url(#filter4_d_3191_4249)">
        <path
          d="M111.316 62.1099V96.3359C111.316 101.204 107.425 105.098 102.472 105.098H39.8558C34.9916 105.098 31.0117 101.204 31.0117 96.3359V61.9329C31.0117 61.7559 31.0117 61.5789 31.1002 61.4019H48.267C51.274 61.4019 53.7503 63.7916 53.7503 66.8893C53.7503 68.3939 54.3694 69.8101 55.3422 70.7836C56.4035 71.8457 57.6417 72.3768 59.2337 72.3768H84.1819C87.1889 72.3768 89.6653 69.9871 89.6653 66.8893C89.6653 65.3847 90.2844 63.9686 91.2572 62.995C92.3185 61.9329 93.5567 61.4019 95.0602 61.4019H111.139C111.316 61.5789 111.316 61.8444 111.316 62.1099Z"
          fill="white"
        />
      </g>
      <g filter="url(#filter5_d_3191_4249)">
        <path
          d="M116.53 60.3V100.1C116.53 105.6 112.133 110 106.537 110H35.7906C30.2947 110 25.7981 105.6 25.7981 100.1V60.1C25.7981 59.9 25.7981 59.7 25.898 59.5H48.6809C52.0783 59.5 54.8762 62.2 54.8762 65.7C54.8762 67.4 55.5757 69 56.6749 70.1C57.874 71.3 59.2729 71.9 61.0716 71.9H81.3563C84.7538 71.9 87.5517 69.2 87.5517 65.7C87.5517 64 88.2512 62.4 89.3503 61.3C90.5494 60.1 91.9484 59.5 93.6471 59.5H116.33C116.53 59.7 116.53 60 116.53 60.3Z"
          fill="url(#paint1_linear_3191_4249)"
        />
      </g>
      <path
        d="M106.537 95.6H85.9528C85.2534 95.6 84.6538 95.1 84.6538 94.3C84.6538 93.6 85.1534 93 85.9528 93H106.338C107.037 93 107.637 93.5 107.637 94.3C107.736 94.9 107.137 95.6 106.537 95.6Z"
        fill="#E6ECF5"
      />
      <path
        d="M97.3443 101.9H85.9528C85.2534 101.9 84.6538 101.4 84.6538 100.6C84.6538 99.9 85.1534 99.3 85.9528 99.3H97.2443C97.9438 99.3 98.5434 99.8 98.5434 100.6C98.6433 101.2 98.0437 101.9 97.3443 101.9Z"
        fill="#E6ECF5"
      />
      <path
        d="M90.2569 106.1C89.8572 107.8 89.2577 109.6 88.5582 111.1C86.6596 114.8 83.6619 117.7 79.9647 119.6C76.1675 121.5 71.6709 122.3 67.1743 121.3C56.4823 119 49.6874 108.7 51.8858 98.1C54.0841 87.5 64.3764 80.6 74.9684 82.9C78.7656 83.7 82.0631 85.6 84.861 88.2C89.6574 92.9 91.5559 99.6 90.2569 106.1Z"
        fill="url(#paint2_linear_3191_4249)"
      />
      <path
        d="M77.2596 100.3H72.763V95.8001C72.763 94.9001 72.0635 94.1001 71.0642 94.1001C70.1649 94.1001 69.3655 94.8001 69.3655 95.8001V100.3H64.8689C63.9696 100.3 63.1702 101 63.1702 102C63.1702 103 63.8696 103.7 64.8689 103.7H69.3655V108.2C69.3655 109.1 70.065 109.9 71.0642 109.9C71.9636 109.9 72.763 109.2 72.763 108.2V103.7H77.2596C78.1589 103.7 78.9583 103 78.9583 102C78.9583 101 78.1589 100.3 77.2596 100.3Z"
        fill="white"
      />
      <path
        d="M128.903 39.8C131.166 39.8 133 37.9643 133 35.7C133 33.4356 131.166 31.6 128.903 31.6C126.64 31.6 124.806 33.4356 124.806 35.7C124.806 37.9643 126.64 39.8 128.903 39.8Z"
        fill="#EAEEF9"
      />
      <path
        d="M124.806 26.5C126.351 26.5 127.604 25.2464 127.604 23.7C127.604 22.1536 126.351 20.9 124.806 20.9C123.261 20.9 122.008 22.1536 122.008 23.7C122.008 25.2464 123.261 26.5 124.806 26.5Z"
        fill="#EAEEF9"
      />
      <path
        d="M11.4913 35.7C13.0365 35.7 14.2892 34.4464 14.2892 32.9C14.2892 31.3536 13.0365 30.1 11.4913 30.1C9.94602 30.1 8.69336 31.3536 8.69336 32.9C8.69336 34.4464 9.94602 35.7 11.4913 35.7Z"
        fill="#EAEEF9"
      />
      <path
        d="M5.19609 97.3C8.06582 97.3 10.3922 94.9719 10.3922 92.1C10.3922 89.2281 8.06582 86.9 5.19609 86.9C2.32637 86.9 0 89.2281 0 92.1C0 94.9719 2.32637 97.3 5.19609 97.3Z"
        fill="#EAEEF9"
      />
      <defs>
        <filter
          id="filter0_d_3191_4249"
          x="34.4751"
          y="0.5"
          width="73.5779"
          height="83.1"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3191_4249" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3191_4249"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3191_4249"
          x="25.4817"
          y="8.80005"
          width="91.6641"
          height="83.1"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3191_4249" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3191_4249"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_3191_4249"
          x="20.3857"
          y="18.6"
          width="101.957"
          height="83"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3191_4249" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3191_4249"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_3191_4249"
          x="14.8899"
          y="28.1"
          width="112.948"
          height="82.9"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.18 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3191_4249" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3191_4249"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_3191_4249"
          x="18.0117"
          y="45.4019"
          width="106.304"
          height="69.6963"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="6.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3191_4249" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3191_4249"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_3191_4249"
          x="3.7981"
          y="48.5"
          width="134.732"
          height="94.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3191_4249" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3191_4249"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3191_4249"
          x1="71.1712"
          y1="27.1805"
          x2="71.1712"
          y2="75.3392"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3191_4249"
          x1="71.1344"
          y1="58.3319"
          x2="71.1344"
          y2="110.544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3191_4249"
          x1="71.0672"
          y1="83.8218"
          x2="71.0672"
          y2="105.867"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  )
})

export default EmptyIcon2
