import React, { memo, useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'antd'
import CSS from './index.module.scss'
import useMainStore from '@/store/main'
import useChatStore from '@/store/chat'
import { useRequest } from '@/hooks/useRequest'
import * as UserAPI from '@/api/modules/user'
import * as CreateAPI from '@/api/modules/create'
import TurndownService from 'turndown'
import classNames from 'classnames'
import { Markdown } from '../markdown'
import { messageTool } from '@/utils/tool'
import useListStore from '@/store/list'
const turndownService = new TurndownService()

const EditReportModal = memo(() => {
  const editReportReq = useRequest(UserAPI.editReport)
  const saveReportDraftsReq = useRequest(CreateAPI.saveReportDrafts)

  const { editReportModalSwitch, changeSwitchHandle } = useMainStore(state => ({
    editReportModalSwitch: state.editReportModalSwitch,
    changeSwitchHandle: state.changeSwitchHandle,
  }))

  const { reportId, type, changeContent } = useChatStore(state => ({
    content: state.content,
    reportId: state.reportId,
    type: state.type,
    changeContent: state.changeContent,
  }))

  const getdraftsList = useListStore(state => state.getdraftsList)
  const draftsListInfo = useListStore(state => state.draftsListInfo)

  const [content, setContent] = useState('')
  const contenteditableRef = useRef<any>(null)

  const getEditReport = async () => {
    const [err, res] = await editReportReq.run({ id: reportId, type })
    if (err) return
    setContent(res.data.answer)
  }

  const handleOk = async () => {
    const newContent = turndownService.turndown(contenteditableRef.current?.children?.[0] ?? '')
    const [err, res] = await editReportReq.run({ id: reportId, content: newContent, type })
    if (err) return
    messageTool(res.msg)
    changeContent(newContent)
    changeSwitchHandle('editReportModalSwitch', false)
    setContent('')
  }

  const handleSaveDraft = async () => {
    const newContent = turndownService.turndown(contenteditableRef.current?.children?.[0] ?? '')
    const [err, res] = await saveReportDraftsReq.run({ id: reportId, content: newContent })
    if (err) return
    messageTool(res.msg)
    if (draftsListInfo.page === 1) {
      getdraftsList()
    }
  }

  useEffect(() => {
    if (editReportModalSwitch) {
      getEditReport()
    }
  }, [editReportModalSwitch])

  return (
    <Modal
      className={CSS.my_modal}
      width={800}
      title="编辑内容"
      open={editReportModalSwitch}
      onCancel={() => changeSwitchHandle('editReportModalSwitch', false)}
      footer={
        <div className={CSS.footer_box}>
          <Button
            type="primary"
            ghost
            className={classNames([CSS.btn, 'aiq-btn-ghost'])}
            onClick={() => changeSwitchHandle('editReportModalSwitch', false)}
          >
            取消
          </Button>
          <Button
            type="primary"
            ghost
            className={classNames([CSS.btn, 'aiq-btn-ghost'])}
            onClick={handleSaveDraft}
            loading={saveReportDraftsReq.loading}
          >
            保存草稿箱
          </Button>
          <Button
            type="primary"
            className={classNames([CSS.btn, 'aiq-btn-primary'])}
            onClick={handleOk}
            loading={editReportReq.loading}
          >
            保存
          </Button>
        </div>
      }
    >
      <div className={classNames([CSS.modal_box, 'scrollbar_style'])}>
        <div
          suppressContentEditableWarning
          contentEditable
          ref={contenteditableRef}
          className={classNames([CSS.content])}
        >
          <Markdown content={content} />
        </div>
      </div>
    </Modal>
  )
})

export default EditReportModal
