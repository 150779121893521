import React, { memo } from 'react'
import Login from '../Login'
import Agreement from '../Agreement'
import EmailModal from '../EmailModal'
import CareerModal from '../CareerModal'
import SendEmailModal from '../SendEmailModal'
import TemplateModal from '../TemplateModal'
import EditReportModal from '../EditReportModal'

const GlobalPopUp = memo(() => {
  return (
    <>
      <Login />
      <Agreement />
      <EmailModal />
      <CareerModal />
      <SendEmailModal />
      <TemplateModal />
      <EditReportModal />
    </>
  )
})

export default GlobalPopUp
