import React, { memo } from 'react'

const EmailIcon = memo(() => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M14.6667 13.338C14.6654 13.5131 14.5954 13.6807 14.4717 13.8045C14.3479 13.9284 14.1804 13.9986 14.0053 14H1.99467C1.81921 13.9998 1.651 13.93 1.52699 13.8059C1.40299 13.6817 1.33333 13.5135 1.33333 13.338V12.6667H13.3333V4.86667L8 9.66667L1.33333 3.66667V2.66667C1.33333 2.48986 1.40357 2.32029 1.5286 2.19526C1.65362 2.07024 1.82319 2 2 2H14C14.1768 2 14.3464 2.07024 14.4714 2.19526C14.5964 2.32029 14.6667 2.48986 14.6667 2.66667V13.338ZM2.956 3.33333L8 7.87333L13.044 3.33333H2.956ZM0 10H5.33333V11.3333H0V10ZM0 6.66667H3.33333V8H0V6.66667Z"
          fill="white"
        />
      </g>
    </svg>
  )
})

export default EmailIcon
