import React, { memo, useEffect } from 'react'
import CSS from './index.module.scss'
import AddIcon from '@/assets/svg/AddIcon'
import DeleteIcon from '@/assets/svg/DeleteIcon'
import classNames from 'classnames'
import EmptyIcon2 from '@/assets/svg/EmptyIcon2'
import { useRequest } from '@/hooks/useRequest'
import * as DraftsAPI from '@/api/modules/drafts'
import moment from 'moment'
import useUserStore from '@/store/user'
import { Popconfirm } from 'antd'
import antdUI from '@/antd-ui'
import useListStore from '@/store/list'
import useMainStore from '@/store/main'

const TemplateBox = memo(() => {
  const token = useUserStore(state => state.token)
  const deleteTemplateReq = useRequest(DraftsAPI.deleteDrafts)
  const getTemplateReq = useRequest(DraftsAPI.getDrafts)
  const { templateListInfo, getTemplateList } = useListStore(state => ({
    templateListInfo: state.templateListInfo,
    getTemplateList: state.getTemplateList,
  }))

  const changeUseTemplateInfo = useMainStore(state => state.changeUseTemplateInfo)
  const changeTemplateModalInfo = useMainStore(state => state.changeTemplateModalInfo)
  const templateModalInfo = useMainStore(state => state.templateModalInfo)

  useEffect(() => {
    if (token) {
      getTemplateList()
    }
  }, [token])

  const deleteDraftsItem = async (id: number) => {
    const [err, res] = await deleteTemplateReq.run({ id })
    if (err) return
    antdUI.messageApi.open({
      type: 'success',
      content: res.msg,
    })
    // 删除如果是当前模板，而且正在使用的情况下 清空id，因为删除了 没有保存了
    if (id === templateModalInfo.id) {
      changeTemplateModalInfo((state: any) => ({ ...state, data: {}, id: null }))
    }
    getTemplateList()
  }

  const useDraftsItem = async (id: number) => {
    const [err, res] = await getTemplateReq.run({ id })
    if (err) return
    changeUseTemplateInfo({ data: res.data, id })
    changeTemplateModalInfo((state: any) => ({ ...state, id }))
  }

  return (
    <div className={CSS.wrapper}>
      {templateListInfo.list.length > 0 && <div className={CSS.title}>模板箱</div>}
      <div className={classNames([CSS.list, 'scrollbar_style'])}>
        {templateListInfo.list.length ? (
          templateListInfo.list.map((v: any, i) => (
            <div className={CSS.list_item} key={i}>
              <div className={classNames([CSS.top, 'flex justify-between'])}>
                <div className={CSS.top_left}>{v.title}</div>
                <div className={CSS.top_right}>
                  {moment(v.updatetime * 1000).format('YYYY年MM月DD日 HH:mm')}
                </div>
              </div>
              <div className={CSS.center}>
                <div>{v.career}</div>
              </div>
              <div className={CSS.footer}>
                <Popconfirm
                  title="是否使用该模板"
                  placement="top"
                  okText="使用"
                  cancelText="取消"
                  onConfirm={() => useDraftsItem(v.id)}
                >
                  <div className={classNames([CSS.footer_it, 'icon_formal_activate'])}>
                    <AddIcon />
                    <span>使用</span>
                  </div>
                </Popconfirm>

                <Popconfirm
                  title="是否删除该职业"
                  placement="topRight"
                  okText="删除"
                  cancelText="取消"
                  onConfirm={() => deleteDraftsItem(v.id)}
                >
                  <div className={classNames([CSS.footer_it, 'icon_del_activate'])}>
                    <DeleteIcon />
                    <span>删除</span>
                  </div>
                </Popconfirm>
              </div>
            </div>
          ))
        ) : (
          <div className={CSS.empty_box}>
            <EmptyIcon2 />
            <div className={CSS.empty_text}>暂无模板</div>
          </div>
        )}
      </div>
    </div>
  )
})

export default TemplateBox
