import React, { memo } from 'react'

const EmptyIcon3 = memo(() => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="136"
      height="114"
      viewBox="0 0 136 114"
      fill="none"
    >
      <path
        d="M121.398 77.2637C123.672 77.2637 125.515 75.457 125.515 73.2283C125.515 70.9996 123.672 69.1928 121.398 69.1928C119.124 69.1928 117.281 70.9996 117.281 73.2283C117.281 75.457 119.124 77.2637 121.398 77.2637Z"
        fill="#EAEEF9"
      />
      <path
        d="M113.492 88.5228C115.045 88.5228 116.303 87.2889 116.303 85.7669C116.303 84.2448 115.045 83.011 113.492 83.011C111.939 83.011 110.68 84.2448 110.68 85.7669C110.68 87.2889 111.939 88.5228 113.492 88.5228Z"
        fill="#EAEEF9"
      />
      <path
        d="M22.3919 21.1612C23.9446 21.1612 25.2034 19.9274 25.2034 18.4053C25.2034 16.8833 23.9446 15.6494 22.3919 15.6494C20.8391 15.6494 19.5803 16.8833 19.5803 18.4053C19.5803 19.9274 20.8391 21.1612 22.3919 21.1612Z"
        fill="#EAEEF9"
      />
      <path
        d="M5.22142 74.3111C8.10513 74.3111 10.4428 72.0196 10.4428 69.1929C10.4428 66.3663 8.10513 64.0748 5.22142 64.0748C2.33771 64.0748 0 66.3663 0 69.1929C0 72.0196 2.33771 74.3111 5.22142 74.3111Z"
        fill="#EAEEF9"
      />
      <path
        d="M68.6315 100C96.7468 100 119.54 77.6575 119.54 50C119.54 22.3425 96.7468 0 68.6315 0C40.5162 0 17.7227 22.3425 17.7227 50C17.7227 77.6575 40.5162 100 68.6315 100Z"
        fill="#EAEEF9"
      />
      <path
        d="M98.1966 7.5153C99.7109 7.5153 100.992 8.65145 100.992 10.2421V90.6815C100.992 92.1585 99.8274 93.4082 98.1966 93.4082H37.5094C35.9952 93.4082 34.7139 92.2721 34.7139 90.6815V10.2421C34.7139 8.76507 35.8787 7.5153 37.5094 7.5153H98.1966Z"
        fill="#CED7E2"
      />
      <path
        d="M100.992 15.9227V90.5678C100.992 92.0448 99.8274 93.2945 98.1966 93.2945H42.7511L37.8589 88.5227L92.9549 14.5593L97.7307 12.8551L100.992 15.9227Z"
        fill="#BCC4CF"
      />
      <path
        d="M97.9637 13.764C97.9637 13.3095 97.6142 12.8551 97.0318 12.8551H38.7907C38.3248 12.8551 37.8589 13.1959 37.8589 13.764V87.6138C37.8589 88.0682 38.2083 88.5227 38.7907 88.5227H96.9153C97.3813 88.5227 97.8472 88.1819 97.8472 87.6138L97.9637 13.764Z"
        fill="#D9DFEE"
      />
      <path
        d="M97.9637 13.764C97.9637 13.3095 97.6142 12.8551 97.0318 12.8551H38.7907C38.3248 12.8551 37.8589 13.1959 37.8589 13.764C38.5578 43.1903 38.7907 76.4795 35.5292 85.1143L91.3242 84.3189C94.7022 69.3218 97.2648 42.395 97.9637 13.764Z"
        fill="#EFF3FB"
      />
      <g filter="url(#filter0_d_3189_4223)">
        <path
          d="M97.8468 12.9688C97.8468 12.9688 97.7303 17.3998 96.3325 45.69C96.3325 45.9172 96.3325 46.0308 96.3325 46.258C94.5853 73.9801 69.4251 79.7745 66.3966 80.1153C64.9988 80.2289 62.3197 80.4562 57.544 80.4562C51.6034 80.6834 42.5178 80.797 28.307 80.9106C27.841 80.9106 27.4916 80.4562 27.7245 80.0017C37.509 60.8008 37.6255 13.0824 37.6255 13.0824L97.8468 12.9688Z"
          fill="white"
        />
      </g>
      <path
        d="M96.2164 46.1445C94.5857 73.8666 69.309 79.6609 66.2805 80.0018C64.8827 80.1154 62.2036 80.3426 57.4279 80.3426C70.0079 74.6619 76.7639 64.4365 76.6474 56.4835C82.7045 56.9379 93.1879 56.4835 96.2164 46.1445Z"
        fill="#EAEEF9"
      />
      <path
        d="M82.0056 7.74259C82.0056 7.62898 82.0056 7.62898 82.0056 7.74259L81.4232 7.17451C81.4232 7.17451 81.0738 7.28813 80.9573 7.51536H71.8717C71.8717 7.28813 71.8717 7.17451 71.8717 6.94728C71.8717 5.12944 70.2409 3.53883 68.3772 3.53883C66.5135 3.53883 64.8828 5.12944 64.8828 6.94728C64.8828 7.17451 64.8827 7.28813 64.9992 7.40174H55.5642C55.0983 7.40174 54.6323 7.74259 54.6323 8.42428V11.3783C54.6323 13.6506 56.2631 15.014 58.4762 15.014H77.8123C80.1419 15.014 82.1221 13.6506 82.1221 11.3783V8.31067C82.2386 8.08344 82.1221 7.85621 82.0056 7.74259Z"
        fill="#1C3754"
      />
      <path
        d="M81.6561 7.74262V10.6966C81.6561 10.9238 81.6561 11.0375 81.6561 11.2647C81.3067 13.1961 79.6759 14.7868 77.5793 14.7868H58.1267C56.0301 14.7868 54.3993 13.1961 54.0499 11.2647C54.0499 11.0375 54.0499 10.9238 54.0499 10.6966V7.74262C54.0499 7.28816 54.3993 6.72009 54.9817 6.72009H64.5333C64.5333 6.49286 64.5333 6.37924 64.5333 6.26563C64.5333 4.44779 66.164 2.85718 68.0277 2.85718C69.8914 2.85718 71.5222 4.44779 71.5222 6.26563C71.5222 6.49286 71.5222 6.60647 71.5222 6.72009H81.0737C81.3067 6.8337 81.6561 7.17455 81.6561 7.74262Z"
        fill="url(#paint0_linear_3189_4223)"
      />
      <path
        d="M67.7948 7.85615C68.7267 7.85615 69.4256 7.17446 69.4256 6.26554C69.4256 5.35662 68.7267 4.67493 67.7948 4.67493C66.863 4.67493 66.1641 5.35662 66.1641 6.26554C66.1641 7.17446 66.9794 7.85615 67.7948 7.85615Z"
        fill="#EAEEF9"
      />
      <path
        d="M81.5396 11.2646C81.1902 13.196 79.5595 14.7866 77.4628 14.7866H58.1267C56.0301 14.7866 54.3993 13.196 54.0499 11.2646H81.5396Z"
        fill="#9AA1B2"
      />
      <path
        d="M52.1861 45.8037C54.0498 45.8037 55.4476 44.3267 55.4476 42.6225C55.4476 40.8047 53.9334 39.4413 52.1861 39.4413C50.3224 39.4413 48.9246 40.9183 48.9246 42.6225C48.8082 44.3267 50.3224 45.8037 52.1861 45.8037Z"
        fill="#989FB0"
      />
      <path
        d="M78.0452 45.6899C79.9089 45.6899 81.3067 44.2129 81.3067 42.5086C81.3067 40.6908 79.7924 39.3274 78.0452 39.3274C76.298 39.3274 74.7837 40.8044 74.7837 42.5086C74.7837 44.2129 76.1815 45.6899 78.0452 45.6899Z"
        fill="#989FB0"
      />
      <path d="M68.2607 49.212H61.9706V50.689H68.2607V49.212Z" fill="#989FB0" />
      <path
        d="M131.045 13.3098C133.607 25.1257 133.258 37.6234 129.763 49.2121C128.948 51.598 128.132 54.2112 126.385 56.029C123.939 58.8694 119.513 60.1192 115.902 59.2102C112.174 58.3013 109.146 55.1201 108.447 51.2572C107.865 48.8713 108.68 46.0309 110.777 44.5539C112.99 43.1905 116.135 43.5314 117.999 45.2356C120.095 46.9398 120.911 49.553 120.794 52.0525C120.678 54.552 119.746 57.0516 118.464 59.2102C114.621 66.2544 107.632 71.8215 99.5943 74.321C93.6537 76.1389 87.2472 76.1389 81.3066 74.5483"
        stroke="#C9D4E2"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeDasharray="4 4"
      />
      <path
        d="M135.937 9.90118C135.587 11.1509 134.19 11.6054 132.792 10.8101C131.278 10.1284 130.229 9.56034 130.462 8.42419C130.812 7.28804 132.209 7.17442 133.84 7.06081C135.82 6.83358 136.17 8.65142 135.937 9.90118Z"
        fill="#DAE2EB"
      />
      <path
        d="M124.638 11.0373C125.22 12.0598 126.851 12.7415 128.016 11.719C129.297 10.5828 130.346 9.78754 129.763 8.65139C129.181 7.62886 128.249 7.9697 126.269 8.19693C124.638 8.53778 123.939 9.90116 124.638 11.0373Z"
        fill="#DAE2EB"
      />
      <path
        d="M129.763 6.83364C130.579 6.72002 131.394 7.17448 131.627 7.85617C131.743 8.0834 131.86 8.42425 131.86 8.65148C132.093 10.2421 131.51 11.6055 130.579 11.7191C129.53 11.9463 128.482 10.8102 128.365 9.33317C128.365 8.87871 128.365 8.65148 128.365 8.31063C128.482 7.51533 128.948 6.94725 129.763 6.83364C129.88 6.83364 129.763 6.83364 129.763 6.83364Z"
        fill="#989FB0"
      />
      <defs>
        <filter
          id="filter0_d_3189_4223"
          x="5.65039"
          y="1.96883"
          width="114.196"
          height="111.942"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3189_4223" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3189_4223"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3189_4223"
          x1="54.0832"
          y1="8.82344"
          x2="81.6827"
          y2="8.82344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  )
})

export default EmptyIcon3
