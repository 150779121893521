import React, { memo } from 'react'
import CSS from './index.module.scss'
import LogoIcon from '@/assets/icons/LogoIcon'
import classNames from 'classnames'
import useMainStore from '@/store/main'

const rightElement = (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none">
    <path
      d="M7.97063 5.20759C8.49064 5.6079 8.49064 6.3921 7.97063 6.79241L1.60999 11.6888C0.952424 12.195 -5.99994e-07 11.7263 -5.63721e-07 10.8964L-1.35662e-07 1.10358C-9.93882e-08 0.273737 0.952425 -0.195026 1.60999 0.311171L7.97063 5.20759Z"
      fill="#F3F7FF"
    />
  </svg>
)
const leftElement = (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12" fill="none">
    <path
      d="M1.02937 5.20759C0.509358 5.6079 0.509358 6.3921 1.02937 6.79241L7.39001 11.6888C8.04758 12.195 9 11.7263 9 10.8964L9 1.10358C9 0.273737 8.04758 -0.195026 7.39001 0.311171L1.02937 5.20759Z"
      fill="#F3F7FF"
    />
  </svg>
)

const Header = memo(props => {
  const changeSideInfo = useMainStore(state => state.changeSideInfo)
  const sideInfo = useMainStore(state => state.sideInfo)
  return (
    <header className={CSS.wrapper}>
      <div className={CSS.left}>
        <div
          className={classNames([CSS.hidden_box])}
          onClick={() => changeSideInfo((state: any) => ({ ...state, left: !state.left }))}
        >
          <div className={classNames([sideInfo.left && CSS.hide_left])}>{leftElement}</div>
          <span className="ml-1.5">生成记录</span>
        </div>
      </div>
      <div className={classNames([CSS.center, 'flex items-center'])}>
        <LogoIcon />
        <span>AIQ报告生成助手</span>
      </div>
      <div className={CSS.right}>
        <div
          className={classNames([CSS.hidden_box])}
          onClick={() => changeSideInfo((state: any) => ({ ...state, right: !state.right }))}
        >
          <span>个人中心</span>
          <div className={classNames(['ml-1.5', sideInfo.right && CSS.hide_right])}>
            {rightElement}
          </div>
        </div>
      </div>
    </header>
  )
})

export default Header
