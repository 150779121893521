import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import antdUI from '@/antd-ui'
import cache from '@/utils/cache'
import useUserStore from './user'
import { awaitToRequest } from '@/utils'
import * as UserAPI from '@/api/modules/user'
import * as DraftsAPI from '@/api/modules/drafts'

const store = (set: any, get: any) => ({
  careerListInfo: {
    list: [
      {
        name: '项目助理',
        id: 1,
      },
      {
        name: '人事专员',
        id: 2,
      },
      {
        name: '行政主管',
        id: 3,
      },
      {
        name: '前端开发',
        id: 4,
      },
      {
        name: '后端开发',
        id: 5,
      },
      {
        name: '销售经理',
        id: 6,
      },
    ],
  },
  myCareerListInfo: {
    list: [],
  },
  templateListInfo: {
    list: [],
  },
  historyListInfo: {
    list: [],
    total: 0,
    page: 1,
    size: 20,
    loading: false,
  },
  collectListInfo: {
    list: [],
    total: 0,
    page: 1,
    size: 20,
    loading: false,
  },
  draftsListInfo: {
    list: [],
    total: 0,
    page: 1,
    size: 20,
    loading: false,
  },
  async getCareerList() {
    const [err, res] = await awaitToRequest(UserAPI.getCareerList())
    if (err) return
    set((state: any) => ({
      careerListInfo: {
        ...state.careerListInfo,
        list: res?.data,
      },
    }))
  },
  async getMyCareerList() {
    const [err, res] = await awaitToRequest(UserAPI.getMyCareersList({ page: 1, size: 9999999 }))
    if (err) return
    set((state: any) => ({
      myCareerListInfo: {
        ...state.myCareerListInfo,
        list: res?.data?.list ?? [],
      },
    }))
  },
  async getTemplateList() {
    const [err, res] = await awaitToRequest(DraftsAPI.getDraftsList({ page: 1, size: 9999999 }))
    if (err) return
    set((state: any) => ({
      templateListInfo: {
        ...state.templateListInfo,
        list: res?.data.list,
      },
    }))
  },
  handleListPageAndSize(listInfo: any, isPush = true) {
    let page: any, size: any
    if (isPush) {
      page = listInfo.page + 1
      size = listInfo.size
    } else {
      page = 1
      size = (listInfo.page || 1) * listInfo.size
      console.log('listInfo.page: ', listInfo.page)
      if (listInfo.page !== 1) {
        size = listInfo.list.length + 1
      }
      if (size === listInfo.total) {
        size = listInfo.total + 1
      }
    }
    return { page, size }
  },
  async getHistoryList(page = 1, size = 20) {
    const [err, res] = await awaitToRequest(UserAPI.getList({ page, size, type: 'history' }))
    if (err) return
    set((state: any) => ({
      historyListInfo: {
        ...state.historyListInfo,
        list: res?.data.list,
        total: res?.data.total,
        page,
        size,
      },
    }))
  },
  async getCollectList(page = 1, size = 20) {
    const [err, res] = await awaitToRequest(UserAPI.getList({ page, size, type: 'collection' }))
    if (err) return
    set((state: any) => ({
      collectListInfo: {
        ...state.collectListInfo,
        list: res?.data.list,
        total: res?.data.total,
        page,
        size,
      },
    }))
  },
  async getdraftsList(page = 1, size = 20) {
    const [err, res] = await awaitToRequest(UserAPI.getList({ page, size, type: 'drafts' }))
    if (err) return
    set((state: any) => ({
      draftsListInfo: {
        ...state.draftsListInfo,
        list: res?.data.list,
        total: res?.data.total,
        page,
        size,
      },
    }))
  },
})

const useListStore = create(store)

export default useListStore
