import React, { memo, useState } from 'react'
import CSS from './index.module.scss'
import { Button } from 'antd'
import Consent from '../Consent'
import classNames from 'classnames'
import PassIcon from '@/assets/svg/PassIcon'
import GetCaptcha from '../GetCaptcha'

const CodeLogin = memo((props: any = {}) => {
  const { loginFormData } = props

  return (
    <div>
      <GetCaptcha form={loginFormData} onChange={props.onChange} />
      <Consent isAgree={props.isAgree} setIsAgree={props.setIsAgree} />
      <div className={classNames([CSS.btn_box, 'mt-4'])}>
        <Button
          className={classNames([CSS.default_btn, 'aiq-default-btn'])}
          onClick={() => props.setType(-1)}
          disabled={props.loading}
        >
          注册
        </Button>
        <Button
          className="aiq-btn-primary"
          type="primary"
          onClick={props.onSubmit}
          loading={props.loading}
        >
          登录
        </Button>
      </div>
      <div
        className={classNames([CSS.rests, 'flex items-center'])}
        onClick={() => props.setType(1)}
      >
        <PassIcon />
        <span>密码登录</span>
      </div>
    </div>
  )
})

export default CodeLogin
