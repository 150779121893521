import React, { ChangeEvent, memo } from 'react'
import classNames from 'classnames'
import CSS from './index.module.scss'

interface IProps {
  value: string
  placeholder?: string
  disabled?: boolean
  type?: string
  maxLength?: number
  style?: any
  inputStyle?: any
  onChange?: (e: any) => void
  rightChild?: any
  className?: string
}

const Input = memo((props: IProps) => {
  const {
    value = '',
    placeholder = '请输入内容',
    type = 'text',
    maxLength = -1,
    className = '',
    inputStyle = {},
    style = {},
    disabled = false,
    onChange,
    rightChild,
  } = props || {}
  const change = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    if (maxLength !== -1 && value.length > maxLength) {
      return
    }

    if (type === 'number') {
      if (!/^\d+$/.test(value) && value !== '') {
        return
      }
    }

    onChange?.(e)
  }
  return (
    <div
      className={classNames([CSS.input_wrapper, className, 'flex', 'items-center'])}
      style={style}
    >
      <input
        type={type}
        className={classNames([CSS.input, disabled && CSS.disabled_text])}
        onChange={change}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        style={inputStyle}
        disabled={disabled}
      />
      {rightChild && <div className={classNames(['ml-2'])}>{rightChild}</div>}
    </div>
  )
})

export default Input
