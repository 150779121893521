import React, { memo } from 'react'

const TextIcon = memo((props: any = {}) => {
  const { color = '#4361FF' } = props
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M13.3333 14.6666H2.66667C2.48986 14.6666 2.32029 14.5964 2.19526 14.4714C2.07024 14.3464 2 14.1768 2 14V1.99998C2 1.82317 2.07024 1.6536 2.19526 1.52858C2.32029 1.40355 2.48986 1.33331 2.66667 1.33331H13.3333C13.5101 1.33331 13.6797 1.40355 13.8047 1.52858C13.9298 1.6536 14 1.82317 14 1.99998V14C14 14.1768 13.9298 14.3464 13.8047 14.4714C13.6797 14.5964 13.5101 14.6666 13.3333 14.6666ZM12.6667 13.3333V2.66665H3.33333V13.3333H12.6667ZM5.33333 4.66665H10.6667V5.99998H5.33333V4.66665ZM5.33333 7.33331H10.6667V8.66665H5.33333V7.33331ZM5.33333 9.99998H10.6667V11.3333H5.33333V9.99998Z"
          fill={color}
        />
      </g>
    </svg>
  )
})

export default TextIcon
