import React, { memo } from 'react'

const close = memo((props: any = {}) => {
  const { color = '#F3F7FF' } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path
        d="M3.2 10L2.5 9.30005L5.3 6.50005L2.5 3.70005L3.2 3.00005L6 5.80005L8.8 3.00005L9.5 3.70005L6.7 6.50005L9.5 9.30005L8.8 10L6 7.20005L3.2 10Z"
        fill={color}
      />
    </svg>
  )
})

export default close
