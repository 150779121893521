import React, { memo, useState } from 'react'
import { Button } from 'antd'
import classNames from 'classnames'
import CSS from './index.module.scss'
import useChatStore from '@/store/chat'

const Generate = memo((props: any = {}) => {
  const isInput = useChatStore(state => state.isInput)
  return (
    <div className={CSS.wrapper}>
      <div className={classNames([CSS.btn1, CSS.btn, isInput && CSS.btn1_activate])}>
        <Button type="primary" danger ghost className="aiq-btn-dashed" onClick={props.onStop}>
          停止响应
        </Button>
      </div>
      <div className={classNames([CSS.btn2, CSS.btn])}>
        <Button
          type="primary"
          className="aiq-btn-primary"
          onClick={props.onStart}
          loading={isInput}
        >
          {isInput ? '生成中' : '开始生成'}
        </Button>
      </div>
    </div>
  )
})

export default Generate
