import React, { memo, useState } from 'react'
import { Modal } from 'antd'
import CSS from './index.module.scss'
import CloseIcon from '@/assets/svg/CloseIcon'
import useMainStore from '@/store/main'
import NameIcon from '@/assets/svg/NameIcon'
import CodeLogin from './cpns/CodeLogin'
import PassLogin from './cpns/PassLogin'
import Register from './cpns/Register'
import ArrowsLeft1 from '@/assets/svg/ArrowsLeft1'
import ForgotPass from './cpns/ForgotPass'
import antdUI from '@/antd-ui'
import { verifyMobile } from '@/utils'
import * as UserAPI from '@/api/modules/user'
import { useRequest } from '@/hooks/useRequest'
import useUserStore from '@/store/user'

const Login = memo(() => {
  const { loginSwitch, changeSwitchHandle } = useMainStore()
  const { setToken, fetchUserInfo } = useUserStore(state => ({
    setToken: state.setToken,
    fetchUserInfo: state.fetchUserInfo,
  }))
  const [type, setType] = useState(0)

  const [isAgree, setIsAgree] = useState(false)

  const [loginFormData, setLoginFormData] = useState({
    mobile: '',
    captcha: '',
    password: '',
  })

  const [registerFormData, setRegisterFormData] = useState({
    mobile: '',
    captcha: '',
    code: '',
    password: '',
    check_password: '',
  })

  const [forgotPassForm, setForgotPassForm] = useState({
    mobile: '',
    captcha: '',
    newpassword: '',
    confirm_password: '',
  })

  const mobileLoginReq = useRequest(UserAPI.mobilelogin)
  const passLoginReq = useRequest(UserAPI.login)
  const forgotPassReq = useRequest(UserAPI.forgetpwd)
  const registerReq = useRequest(UserAPI.register)

  const onChange = (value: string, type: string, target: any = {}, set: any) => {
    set({
      ...target,
      [type]: value,
    })
  }

  const loginVerifyState = () => {
    if (!verifyMobile(loginFormData.mobile)) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入正确的手机号',
      })
      return
    }
    if (type === 0) {
      if (!loginFormData.captcha.trim()) {
        antdUI.messageApi.open({
          type: 'warning',
          content: '请输入验证码',
        })
        return
      }
    }
    if (type === 1) {
      if (/\s\S*/.test(loginFormData.password)) {
        antdUI.messageApi.open({
          type: 'warning',
          content: '密码不能出现空格',
        })
        return
      }
      if (loginFormData.password.length < 6 || loginFormData.password.length > 18) {
        antdUI.messageApi.open({
          type: 'warning',
          content: '请输入6-18位密码',
        })
        return
      }
    }

    if (!isAgree) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请勾选用户协议',
      })
      return
    }
    return true
  }

  const loginSubmitHandle = async () => {
    if (!loginVerifyState()) return
    let arr: any
    if (type === 0) {
      arr = await mobileLoginReq.run(loginFormData, {}, { code: 1 })
    } else if (type === 1) {
      arr = await passLoginReq.run(loginFormData, {}, { code: 1 })
    }
    const [err, res] = arr
    if (err) return
    setToken(res.data.userinfo.token)
    fetchUserInfo()
    changeSwitchHandle('loginSwitch', false)
    antdUI.notificationApi.success({
      message: '登录成功~',
    })
  }

  const forgotVerifyState = () => {
    if (!verifyMobile(forgotPassForm.mobile)) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入正确的手机号',
      })
      return
    }
    if (!forgotPassForm.captcha.trim()) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入验证码',
      })
      return
    }

    if (forgotPassForm.newpassword !== forgotPassForm.confirm_password) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '俩次密码不一致',
      })
      return
    }

    if (/\s\S*/.test(forgotPassForm.newpassword)) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '密码不能出现空格',
      })
      return
    }
    if (forgotPassForm.newpassword.length < 6 || forgotPassForm.newpassword.length > 18) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入6-18位密码',
      })
      return
    }
    return true
  }

  const forgotSubmitHandle = async () => {
    if (!forgotVerifyState()) return
    const [err, res] = await forgotPassReq.run(forgotPassForm, {}, { code: 1 })
    console.log('err: ', err)
    if (err) return
    antdUI.messageApi.open({
      type: 'success',
      content: res.msg,
    })
    setType(1)
    setForgotPassForm({
      mobile: '',
      captcha: '',
      newpassword: '',
      confirm_password: '',
    })
  }

  const registerVerifyState1 = () => {
    if (!verifyMobile(registerFormData.mobile)) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入正确的手机号',
      })
      return
    }
    if (!registerFormData.captcha.trim()) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入验证码',
      })
      return
    }
    return true
  }
  const registerVerifyState2 = () => {
    if (registerFormData.password !== registerFormData.check_password) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '俩次密码不一致',
      })
      return
    }

    if (/\s\S*/.test(registerFormData.password)) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '密码不能出现空格',
      })
      return
    }
    if (registerFormData.password.length < 6 || registerFormData.password.length > 18) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入6-18位密码',
      })
      return
    }
    if (!isAgree) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请勾选用户协议',
      })
      return
    }
    return true
  }

  const registerNext = () => {
    if (registerVerifyState1()) {
      setType(-2)
    }
  }

  const registerSubmitHandle = async () => {
    if (!registerVerifyState2()) return
    const [err, res] = await registerReq.run(registerFormData, {}, { code: 1 })
    if (err) return
    setToken(res.data.userinfo.token)
    fetchUserInfo()
    changeSwitchHandle('loginSwitch', false)
    antdUI.notificationApi.success({
      message: '登录成功~',
    })
  }

  return (
    <Modal
      className={CSS.login_modal}
      width={'fit-content'}
      open={loginSwitch}
      footer={null}
      closeIcon={null}
    >
      <div className={CSS.modal_box}>
        <div className={CSS.close_btn} onClick={() => changeSwitchHandle('loginSwitch', false)}>
          <CloseIcon />
        </div>
        <div className={CSS.bg}>
          <div className={CSS.circle}></div>
          <img src={require('@/assets/images/renwu.png')} alt="img" />
        </div>
        <div className={CSS.right_box}>
          {type === -1 || type === -2 || type === 2 ? (
            <div className={CSS.title2}>
              <div
                className="cursor-pointer"
                onClick={() => setType(type === -2 ? -1 : type === -1 ? 0 : type === 2 ? 1 : 0)}
              >
                <ArrowsLeft1 />
              </div>
              <div className={CSS.title2_text}>
                {type === -1 || type === -2 ? '注册账号' : type === 2 ? '重置密码' : ''}
              </div>
              <div></div>
            </div>
          ) : (
            <div className={CSS.title}>
              <img src={require('@/assets/images/login_logo.png')} alt="logo" />
              <NameIcon />
            </div>
          )}
          {type === 0 && (
            <CodeLogin
              loginFormData={loginFormData}
              onChange={(value: string, type: string) =>
                onChange(value, type, loginFormData, setLoginFormData)
              }
              setType={setType}
              isAgree={isAgree}
              setIsAgree={setIsAgree}
              loading={mobileLoginReq.loading || passLoginReq.loading}
              onSubmit={loginSubmitHandle}
            />
          )}
          {type === 1 && (
            <PassLogin
              loginFormData={loginFormData}
              onChange={(value: string, type: string) =>
                onChange(value, type, loginFormData, setLoginFormData)
              }
              setType={setType}
              isAgree={isAgree}
              setIsAgree={setIsAgree}
              loading={mobileLoginReq.loading || passLoginReq.loading}
              onSubmit={loginSubmitHandle}
            />
          )}
          {type === 2 && (
            <ForgotPass
              forgotPassForm={forgotPassForm}
              onChange={(value: string, type: string) =>
                onChange(value, type, forgotPassForm, setForgotPassForm)
              }
              setType={setType}
              isAgree={isAgree}
              setIsAgree={setIsAgree}
              loading={forgotPassReq.loading}
              onSubmit={forgotSubmitHandle}
            />
          )}
          {(type === -1 || type === -2) && (
            <Register
              registerFormData={registerFormData}
              onChange={(value: string, type: string) =>
                onChange(value, type, registerFormData, setRegisterFormData)
              }
              type={type}
              setType={setType}
              isAgree={isAgree}
              setIsAgree={setIsAgree}
              onNext={registerNext}
              onSubmit={registerSubmitHandle}
            />
          )}
        </div>
      </div>
    </Modal>
  )
})

export default Login
