import React, { memo } from 'react'

const AddIcon2 = memo((props: any = {}) => {
  const { color = '#8C8D96' } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path
        d="M1.54523 6.99497L1.54523 6.00502L5.50503 6.00502L5.50503 2.04522H6.49497L6.49497 6.00502L10.4548 6.00502L10.4548 6.99497L6.49497 6.99497V10.9548H5.50503V6.99497L1.54523 6.99497Z"
        fill={color}
      />
    </svg>
  )
})

export default AddIcon2
