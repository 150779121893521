import React, { memo } from 'react'
import classNames from 'classnames'
import CSS from './index.module.scss'
import EmptyIcon3 from '@/assets/svg/EmptyIcon3'

const List = memo((props: any = {}) => {
  return (
    <div
      className={classNames([CSS.list_box, 'scrollbar_style'])}
      style={{ display: props.show ? 'block' : 'none' }}
    >
      {props.list?.length ? (
        props.list.map((v: any, i: number) => props.renderItem(v, i))
      ) : (
        <div className={CSS.empty_box}>
          {props.show && <EmptyIcon3 />}
          <div className={CSS.empty_text}>{props.emptyText ? props.emptyText : '暂无数据'}</div>
        </div>
      )}
    </div>
  )
})

export default List
