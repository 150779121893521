import React, { memo, useEffect, useState } from 'react'
import CSS from './index.module.scss'
import { Button, Drawer, Popconfirm } from 'antd'
import useMainStore from '@/store/main'
import EditIcon from '@/assets/svg/EditIcon'
import DeleteIcon from '@/assets/svg/DeleteIcon'
import classNames from 'classnames'
import { useRequest } from '@/hooks/useRequest'
import * as UserAPI from '@/api/modules/user'
import useUserStore from '@/store/user'
import CareerEmptyIcon from '@/assets/svg/CareerEmptyIcon'
import CareerFormModal from './cpns/CareerFormModal'
import antdUI from '@/antd-ui'
import useListStore from '@/store/list'

const CareerModal = memo(() => {
  const { careerModalSwitch, changeSwitchHandle } = useMainStore(state => ({
    careerModalSwitch: state.careerModalSwitch,
    changeSwitchHandle: state.changeSwitchHandle,
  }))
  const token = useUserStore(state => state.token)
  const fetchUserInfo = useUserStore(state => state.fetchUserInfo)
  const delCareerReq = useRequest(UserAPI.delCareer)
  const { myCareerListInfo, getMyCareerList } = useListStore((state: any) => ({
    myCareerListInfo: state?.myCareerListInfo,
    getMyCareerList: state.getMyCareerList,
  }))

  const [careerFormInfo, setCareerFormInfo] = useState({
    open: false,
    title: '添加职业',
    data: null,
  })

  useEffect(() => {
    if (token) {
      getMyCareerList()
    }
  }, [token])

  const careerFormModalOnCancel = (isUpdate = false) => {
    if (typeof isUpdate === 'boolean' && isUpdate) {
      getMyCareerList()
    }
    setCareerFormInfo(state => ({ ...state, open: false }))
  }

  const deleteCareerItem = async (id: number) => {
    const [err, res] = await delCareerReq.run(id)
    if (err) return
    antdUI.messageApi.open({
      type: 'success',
      content: res.msg,
    })
    fetchUserInfo()
    getMyCareerList()
  }
  return (
    <Drawer
      className={CSS.drawer_class}
      width={360}
      title="我的职业"
      placement="right"
      open={careerModalSwitch}
      onClose={() => changeSwitchHandle('careerModalSwitch', false)}
    >
      <CareerFormModal {...careerFormInfo} onCancel={careerFormModalOnCancel} />
      <div className={CSS.modal_box}>
        <div className={classNames([CSS.list, 'scrollbar_style'])}>
          {myCareerListInfo.list.length > 0 ? (
            myCareerListInfo.list.map((v: any, i: number) => (
              <div className={CSS.item} key={i}>
                <div className={classNames([CSS.it_top, 'flex justify-between items-center'])}>
                  <div className={CSS.it_title}>{v.career}</div>
                  <div className={CSS.it_years}>{v.career_age}</div>
                </div>
                <div className={classNames([CSS.it_bottom, 'flex items-center'])}>
                  <div className={CSS.desc}>
                    {v.responsibility ? v.responsibility : '岗位基本职责（未填）'}
                  </div>
                  <div className={classNames([CSS.edit, 'flex items-center'])}>
                    <div
                      className={classNames([CSS.edit_it, 'icon_formal_stroke_active'])}
                      onClick={() => setCareerFormInfo({ title: '编辑职业', open: true, data: v })}
                    >
                      <EditIcon />
                      <span>编辑</span>
                    </div>
                    <Popconfirm
                      title="是否删除该职业"
                      placement="topRight"
                      okText="确定"
                      cancelText="取消"
                      onConfirm={() => deleteCareerItem(v.id)}
                    >
                      <div className={classNames([CSS.edit_it, 'icon_del_activate'])}>
                        <DeleteIcon />
                        <span>删除</span>
                      </div>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={CSS.empty_box}>
              <CareerEmptyIcon />
              <div className={CSS.empty_text}>暂无模板</div>
            </div>
          )}
        </div>
        <div className={CSS.btn_box}>
          <Button
            type="primary"
            className="aiq-btn-primary"
            onClick={() => setCareerFormInfo({ title: '添加职业', open: true, data: null })}
          >
            新增职业
          </Button>
        </div>
      </div>
    </Drawer>
  )
})

export default CareerModal
