import React, { memo } from 'react'

const ShowPassIcon = memo((props: any = {}) => {
  const { toggle = false } = props
  return toggle ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99984 15C14.6022 15 18.3332 10 18.3332 10C18.3332 10 14.6022 5 9.99984 5C5.39746 5 1.6665 10 1.6665 10C1.6665 10 5.39746 15 9.99984 15Z"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M9.99984 12.0832C11.1504 12.0832 12.0832 11.1505 12.0832 9.9999C12.0832 8.84931 11.1504 7.91656 9.99984 7.91656C8.84925 7.91656 7.9165 8.84931 7.9165 9.9999C7.9165 11.1505 8.84925 12.0832 9.99984 12.0832Z"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M2.5 6.66656C2.76447 7.17461 3.16519 7.6459 3.67615 8.06465C5.10875 9.23877 7.40792 9.9999 10 9.9999C12.5921 9.9999 14.8912 9.23877 16.3238 8.06465C16.8348 7.6459 17.2355 7.17461 17.5 6.66656"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M12.0742 10L12.9369 13.2197"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M15.564 8.8974L17.921 11.2544"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M2.0835 11.2544L4.44052 8.8974"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M7.05322 13.2198L7.91593 10"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
})

export default ShowPassIcon
