import React, { memo } from 'react'

const LogoIcon = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="33" viewBox="0 0 34 33" fill="none">
      <g filter="url(#filter0_d_2868_2117)">
        <path
          d="M22.7329 22.3674L25.5855 6.18976C25.6338 5.82425 25.6757 5.22037 25.3557 4.83897C25.129 4.56881 24.748 4.44168 24.2155 4.44168C23.1182 4.44168 22.4266 5.25215 22.1436 6.8572L21.6196 9.82893C21.5552 10.1944 21.1925 10.5123 20.8053 10.5123H8.05723L6.09855 21.6205C5.7707 23.4798 6.71476 24.9895 8.60276 24.9895H8.92549L23.2255 24.9736C22.7321 24.3856 22.5368 23.4798 22.7329 22.3674ZM19.3315 19.6023H10.3595C9.90768 19.6023 9.60099 19.2368 9.67945 18.7918C9.75791 18.3468 10.1935 17.9813 10.6453 17.9813H19.6174C20.0692 17.9813 20.3759 18.3468 20.2974 18.7918C20.219 19.2368 19.7834 19.6023 19.3315 19.6023ZM20.0937 15.2798H11.1217C10.6699 15.2798 10.3632 14.9142 10.4416 14.4693C10.5201 14.0243 10.9557 13.6588 11.4075 13.6588H20.3795C20.8314 13.6588 21.1381 14.0243 21.0596 14.4693C20.9811 14.9142 20.5456 15.2798 20.0937 15.2798Z"
          fill="url(#paint0_linear_2868_2117)"
        />
        <path
          d="M25.3757 24.4015C23.9234 24.4015 24.0142 22.9712 24.1207 22.3674L24.8913 17.9972H27.6507C27.925 17.9972 28.0822 18.2038 28.0374 18.458L27.3285 22.4786C27.3116 22.5739 27.2703 22.717 27.2261 22.8759C27.2205 22.9077 27.196 22.9553 27.1904 22.9871L27.1848 23.0189C27.067 23.3208 26.8875 23.6069 26.6813 23.8612C26.386 24.1631 25.9728 24.4015 25.3757 24.4015Z"
          fill="url(#paint1_linear_2868_2117)"
        />
        <path
          d="M6.39128 9.16146H20.3657L20.7692 6.87307C21.1615 4.64825 22.4058 3.26568 24.1415 3.12266C23.9857 3.09087 23.811 3.07498 23.6496 3.07498H9.49772C8.12609 3.07498 6.81719 4.09204 6.59582 5.34748L5.98496 8.81185C5.95694 8.97076 6.11695 9.16146 6.39128 9.16146Z"
          fill="url(#paint2_linear_2868_2117)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2868_2117"
          x="-4.05515"
          y="-1.97791"
          width="41.9953"
          height="36.7727"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.38416" />
          <feGaussianBlur stdDeviation="2.38416" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0758834 0 0 0 0 0.177779 0 0 0 0 0.58 0 0 0 0.1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2868_2117" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2868_2117"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2868_2117"
          x1="17.9867"
          y1="4.44168"
          x2="17.9867"
          y2="24.9895"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.435417" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.65" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2868_2117"
          x1="26.505"
          y1="17.9972"
          x2="26.505"
          y2="24.4015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.416" />
          <stop offset="1" stopColor="white" stopOpacity="0.7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2868_2117"
          x1="15.5732"
          y1="3.07498"
          x2="15.5732"
          y2="9.16146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="0.526042" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  )
})

export default LogoIcon
