import { request } from '..'
import type { IDataType } from '../type'

enum API {
  saveReportDrafts = '/report/create/saveReportDrafts',
}

/**
 * 保存记录草稿
 */
export function saveReportDrafts(data = {}) {
  return request.post<IDataType>({
    url: API.saveReportDrafts,
    data,
  })
}
