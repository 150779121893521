import React, { memo, useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import CSS from './index.module.scss'
import useMainStore from '@/store/main'
import Input from '@/ui/Input'
import useUserStore from '@/store/user'
import { useRequest } from '@/hooks/useRequest'
import * as UserAPI from '@/api/modules/user'
import { messageTool } from '@/utils/tool'
import { verifyEmail } from '@/utils'
import useChatStore from '@/store/chat'

const SendEmailModal = memo(() => {
  const { changeSwitchHandle, sendEmailModalSwitch } = useMainStore(state => ({
    changeSwitchHandle: state.changeSwitchHandle,
    sendEmailModalSwitch: state.sendEmailModalSwitch,
  }))
  const { reportId } = useChatStore(state => ({
    reportId: state.reportId,
  }))

  const fetchUserInfo = useUserStore(state => state.fetchUserInfo)
  const userInfo = useUserStore(state => state.userInfo)
  const [email, setEmail] = useState(userInfo?.email ?? '')
  const [status, setStatus] = useState(0)

  const sendEmailReq = useRequest(UserAPI.sendEmail)
  const bindEmailReq = useRequest(UserAPI.bindEmail)

  useEffect(() => {
    setStatus(email ? 1 : 0)
    setEmail(userInfo?.email ?? '')
  }, [userInfo?.email])

  const onOkHandle = async () => {
    if (!verifyEmail(email)) {
      return messageTool('请输入正确的邮箱号!', 'error')
    }

    const [err, res] = await sendEmailReq.run({ email, id: reportId, type: '' })
    if (err) return
    messageTool(res.msg)
    changeSwitchHandle('sendEmailModalSwitch', false)
  }

  const bindEmailHandle = async () => {
    if (!verifyEmail(email)) {
      return messageTool('请输入正确的邮箱号!', 'error')
    }
    const [err, res] = await bindEmailReq.run({ email })
    if (err) return
    messageTool('设置成功')
    fetchUserInfo()
  }
  return (
    <Modal
      className={CSS.my_modal}
      width={320}
      title={status === 0 ? '发送到邮箱' : status === 1 ? '发送到默认邮箱' : '发送到其他邮箱'}
      open={sendEmailModalSwitch}
      onCancel={() => {
        changeSwitchHandle('sendEmailModalSwitch', false)
        setStatus(email ? 1 : 0)
      }}
      footer={
        <div className={CSS.btn_box}>
          <Button
            type="primary"
            className="aiq-btn-primary"
            onClick={onOkHandle}
            loading={sendEmailReq.loading}
          >
            发送
          </Button>
        </div>
      }
    >
      <Input
        className="mt-4"
        placeholder="请输入邮箱号"
        inputStyle={{ textAlign: 'center' }}
        disabled={status === 1}
        value={email}
        onChange={(e: any) => setEmail(e.target.value)}
      />
      {[0, 1].includes(status) && (
        <div className={CSS.other}>
          {status == 0 && (
            <div
              className={CSS.other_it}
              onClick={bindEmailReq.loading ? () => ({}) : bindEmailHandle}
            >
              {!bindEmailReq.loading && <div className={CSS.circle}></div>}
              <span className={CSS.def_text}>
                {bindEmailReq.loading ? '正在设置中...' : '设置为默认邮箱'}
              </span>
            </div>
          )}
          {status == 1 && (
            <div className={CSS.other_it} onClick={() => setStatus(2)}>
              <span className={CSS.other_text}>发送到其他邮箱</span>
            </div>
          )}
        </div>
      )}
    </Modal>
  )
})

export default SendEmailModal
