import React, { memo } from 'react'

const EditIcon = memo((props: any = {}) => {
  const { color = '#666666' } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
      <path
        d="M1 13.5H13"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66663 10.8333V8.16667L10.3333 1.5L13 4.16667L6.33329 10.8333H3.66663Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33337 3.5L11 6.16667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})

export default EditIcon
