import React, { memo } from 'react'

const NameIcon = memo(() => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="186"
      height="22"
      viewBox="0 0 186 22"
      fill="none"
    >
      <path
        d="M169.186 22V18.898H173.839V14.9609H163.933V11.9783H173.839V9.30585H166.163V6.39478H173.839V3.84164C171.081 3.91322 168.226 3.96094 165.204 3.96094V0.978298C172.232 0.978298 178.396 0.739687 184.729 0.238602V3.22125C182.21 3.436 179.668 3.57917 177.053 3.69847V6.39478H183.769V9.30585H177.053V11.9783H186V14.9609H177.053V22H169.186Z"
        fill="#404040"
      />
      <path
        d="M140.186 21.5467V18.4686L141.145 18.4209V1.09767H149.996V18.0152L150.764 17.9914V20.4252C152.515 16.9415 153.043 13.6248 153.043 9.63997V7.25385H150.836V4.17576H153.043V0.334114H156.113V4.17576H161.534V21.9523H155.681V18.8981H158.464V7.25385H156.065V9.63997C156.065 14.0781 155.393 17.9675 153.618 21.9523H149.925L150.428 21.0933L140.186 21.5467ZM144.12 18.3016L147.022 18.1584V15.486H144.12V18.3016ZM144.12 12.4795H147.022V9.54453H144.12V12.4795ZM144.12 6.53802H147.022V4.1519H144.12V6.53802Z"
        fill="#404040"
      />
      <path
        d="M115.912 21.9523C117.159 18.1345 117.567 14.7939 117.567 10.3558V2.43384H129.44L129.032 0H132.367L132.774 2.43384H137.907V5.48807H133.302L133.446 6.27549C133.662 7.56399 133.926 8.87636 134.237 10.1887L137.667 7.87419V11.8829L135.125 13.6009C135.916 16.3926 136.9 19.2082 138.075 21.9523H134.285C133.854 20.7592 133.446 19.59 133.086 18.397L129.176 21.0217V17.0846L132.103 15.128C131.311 12.2885 130.639 9.37744 130.112 6.27549L129.968 5.48807H120.685V8.90022H127.929V21.2364H122.988V18.1822H124.859V11.9306H120.661C120.589 15.4382 120.277 18.6594 119.414 21.9523H115.912ZM134.142 0.0954444H136.636L136.9 2.00434H134.405L134.142 0.0954444Z"
        fill="#404040"
      />
      <path
        d="M92.2132 21.475V18.3254H101.592V13.577H93.8203V10.6182H101.592L101.568 6.22776H96.5548C96.243 7.22993 95.8832 8.23209 95.4514 9.2104H91.8774C93.1487 6.49023 94.0602 3.65075 94.468 0.715826H97.6582C97.5622 1.55097 97.4423 2.38611 97.2744 3.22125H101.568V0.238602H104.734V3.22125H113.249V6.22776H104.734V10.6182H112.194V13.577H104.734V18.3254H113.801V21.475H92.2132Z"
        fill="#404040"
      />
      <path
        d="M69.9779 12.551H88.0636V21.7137H69.9779V12.551ZM68.011 11V8.06507H77.7255L77.7015 4.96311H72.7123C72.4725 5.75053 72.1606 6.51409 71.8008 7.27765H68.2748C69.4022 5.10628 70.1698 2.83947 70.4336 0.477214H73.5758C73.5279 1.00216 73.4799 1.52711 73.4079 2.02819H77.7015V0.238602H80.8197V2.02819H88.7832V4.96311H80.8197V8.06507H90.0305V11H68.011ZM73.0961 18.7549H84.9454V15.5098H73.0961V18.7549Z"
        fill="#404040"
      />
      <path
        d="M53.2114 21.9523V0.835127H65.4204L64.6289 8.44684H58.4404V5.60736H61.8944L62.0384 3.77005H56.2816V9.54445H66.0441C65.3245 12.3124 64.3171 14.8178 63.0698 17.0607L66.3079 21.9523H63.0698L61.4627 19.5423C60.863 20.4013 60.2154 21.1887 59.5198 21.9523H53.2114ZM44.1445 15.0325V11.7158L46.807 11.0238V6.68111H44.3844V3.65074H46.807V0.357903H49.9252V3.65074H52.2039V6.68111H49.9252V10.2126L52.2519 9.61604V12.9327L49.9252 13.5293V21.9523H44.9601V18.8742H46.807V14.3405L44.1445 15.0325ZM56.2816 21.475C57.5289 20.1627 58.6083 18.8265 59.5678 17.4664L56.3536 12.3362H56.2816V21.475ZM59.6637 12.3362L61.1748 14.8655C61.6306 14.0304 62.0384 13.1952 62.3742 12.3362H59.6637Z"
        fill="#404040"
      />
      <path
        d="M33.452 19.8764C31.701 19.8764 30.1899 19.5184 28.8706 18.8265C26.2322 17.4186 24.793 14.7939 24.793 11.3341C24.793 6.13232 28.199 2.55314 33.452 2.55314C38.5611 2.55314 42.0871 6.08459 42.0871 11.3341C42.0871 15.0564 40.4321 17.7527 37.5537 19.0173C37.9375 19.757 38.3453 20.6399 38.777 21.6659H35.227C34.8672 20.9024 34.5794 20.282 34.3395 19.8286C33.9797 19.8525 33.6679 19.8764 33.452 19.8764ZM28.5828 11.3341C28.5828 14.6746 30.5017 16.9176 33.452 16.9176C36.3544 16.9176 38.2733 14.6508 38.2733 11.3341C38.2733 8.08893 36.3064 5.44034 33.4041 5.44034C30.5737 5.44034 28.5828 8.11279 28.5828 11.3341Z"
        fill="#404040"
      />
      <path d="M18.3643 19.5662V2.86335H22.322V19.5662H18.3643Z" fill="#404040" />
      <path
        d="M12.1131 19.5662L10.8418 14.8417H5.46889L4.19761 19.5662H0L4.86923 2.86335H11.4655L16.3347 19.5662H12.1131ZM6.23645 11.9783H10.0743L8.17935 4.98699H8.13138L6.23645 11.9783Z"
        fill="#404040"
      />
    </svg>
  )
})

export default NameIcon
