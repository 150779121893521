import React, { memo } from 'react'

const CodeIcon = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <circle cx="8.0001" cy="8.50001" r="5.8" stroke="#8C8D96" strokeWidth="1.2" />
      <path d="M5.6001 8.50001L7.2001 10.1L10.4001 7.70001" stroke="#8C8D96" strokeWidth="1.2" />
    </svg>
  )
})

export default CodeIcon
