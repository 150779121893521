import React, { memo, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import Input from '@/ui/Input'
import CSS from './index.module.scss'
import Consent from '../Consent'
import antdUI from '@/antd-ui'
import { verifyMobile } from '@/utils'

import * as SmsAPI from '@/api/modules/sms'
import { useCaptcha } from '@/hooks/useCaptcha'
import { useRequest } from '@/hooks/useRequest'
import { Button, Spin } from 'antd'
import ShowPassIcon from '@/assets/svg/ShowPassIcon'
import classNames from 'classnames'
import GetCaptcha from '../GetCaptcha'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const Register = memo((props: any = {}) => {
  const { registerFormData } = props
  const [toggle, setToggle] = useState(false)
  const [toggle2, setToggle2] = useState(false)

  return (
    <div className={CSS.wrapper}>
      {props.type === -1 ? (
        <div>
          <GetCaptcha codeEvent="register" form={registerFormData} onChange={props.onChange} />
        </div>
      ) : (
        <div>
          <Input
            type={toggle ? 'text' : 'password'}
            placeholder="请输入密码"
            maxLength={18}
            value={registerFormData.password}
            onChange={(e: any) => props.onChange?.(e.target.value, 'password')}
            rightChild={
              <div className="cursor-pointer flex items-center" onClick={() => setToggle(!toggle)}>
                <ShowPassIcon toggle={toggle} />
              </div>
            }
          />
          <Input
            className="mt-4"
            type={toggle2 ? 'text' : 'password'}
            placeholder="请再次输入密码"
            maxLength={18}
            value={registerFormData.check_password}
            onChange={(e: any) => props.onChange?.(e.target.value, 'check_password')}
            rightChild={
              <div
                className="cursor-pointer flex items-center"
                onClick={() => setToggle2(!toggle2)}
              >
                <ShowPassIcon toggle={toggle2} />
              </div>
            }
          />
          <Consent isAgree={props.isAgree} setIsAgree={props.setIsAgree} />
        </div>
      )}
      <div className={classNames([CSS.btn_box, 'mt-4'])}>
        {props.type === -1 ? (
          <Button className="aiq-btn-primary" type="primary" onClick={props.onNext}>
            下一步
          </Button>
        ) : (
          <Button className="aiq-btn-primary" type="primary" onClick={props.onSubmit}>
            注册并登录
          </Button>
        )}
      </div>
    </div>
  )
})

export default Register
