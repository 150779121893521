import React, { memo, useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import classNames from 'classnames'
import CSS from './index.module.scss'
import Input from '@/ui/Input'
import { useRequest } from '@/hooks/useRequest'
import * as UserAPI from '@/api/modules/user'
import useUserStore from '@/store/user'
import useListStore from '@/store/list'
import { messageTool } from '@/utils/tool'

const ageList = [
  {
    name: '1-3年',
    id: 1,
  },
  {
    name: '3-5年',
    id: 2,
  },
  {
    name: '5-10年',
    id: 3,
  },
  {
    name: '10年以上',
    id: 4,
  },
]

const formState = {
  career: '',
  career_age: '1-3年',
  responsibility: '',
}

const CareerFormModal = memo((props: any = {}) => {
  const addCareerReq = useRequest(UserAPI.addCareer)
  const editCareerReq = useRequest(UserAPI.editCareer)

  const fetchUserInfo = useUserStore(state => state.fetchUserInfo)
  const getCareerList = useListStore(state => state.getCareerList)
  const [form, setForm] = useState(props.data ? props.data : formState)

  useEffect(() => {
    if (props.data) {
      setForm({ ...props.data })
    }
  }, [props.data])

  const ageItemClick = (item: any) => {
    setForm((state: any) => ({
      ...state,
      career_age: item.name,
    }))
  }

  const saveHandle = async () => {
    if (!form.career.trim()) {
      return messageTool('职位名称不能为空', 'warning')
    }
    let arr: any
    if (props.title === '添加职业') {
      arr = await addCareerReq.run(form)
    }
    if (props.title === '编辑职业') {
      arr = await editCareerReq.run(form)
    }
    const [err, res] = arr
    if (err) return
    setForm(formState)
    fetchUserInfo()
    getCareerList()
    props.onCancel(true)
  }

  return (
    <Modal
      className={CSS.modal_class}
      width={328}
      title={props.title}
      open={props.open}
      onCancel={props.onCancel}
      onOk={props.onOk}
      footer={
        <div className={CSS.btn_box}>
          <Button
            type="primary"
            className="aiq-btn-primary"
            onClick={saveHandle}
            loading={addCareerReq.loading || editCareerReq.loading}
          >
            保存
          </Button>
        </div>
      }
    >
      <div className={CSS.modal_box}>
        <div className={CSS.it_box}>
          <div className={CSS.it_title}>职位名称</div>
          <Input
            placeholder="项目助理（10个字以内）"
            style={{ height: '44px' }}
            value={form.career}
            onChange={e =>
              setForm((state: any) => ({
                ...state,
                career: e.target.value,
              }))
            }
          />
        </div>
        <div className={CSS.it_box}>
          <div className={CSS.age_box}>
            {ageList.map(v => (
              <div
                className={classNames([
                  CSS.age_it,
                  form.career_age === v.name && CSS.age_it_activated,
                ])}
                key={v.id}
                onClick={() => ageItemClick(v)}
              >
                {v.name}
              </div>
            ))}
          </div>
        </div>
        <div className={CSS.it_box}>
          <div className={CSS.it_title}>岗位基本职责（选填）</div>
          <Input
            placeholder="协调沟通、会议记录（10个字以内）"
            style={{ height: '44px' }}
            value={form.responsibility}
            onChange={e =>
              setForm((state: any) => ({
                ...state,
                responsibility: e.target.value,
              }))
            }
          />
        </div>
      </div>
    </Modal>
  )
})

export default CareerFormModal
