import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
// import './assets/css/animate.scss'
import './assets/css/theme.scss'
import './assets/css/common.scss'
import './assets/css/markdown.scss'
import './assets/css/highlight.scss'
import App from './App'

import 'animate.css'
import useUserStore from './store/user'
// import 'wowjs/css/libs/animate.css'

async function setup() {
  useUserStore.getState().fetchUserInfo()
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  root.render(<App />)
}

setup()

const key: any = 'aaa'
const obj = { aaa: 111 }

obj[key as keyof typeof obj] = 123
