import React, { memo } from 'react'

const CollectNo = memo((props: any = {}) => {
  const { color = '#4361FF' } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14.3448 6.10065C14.2777 5.90092 14.1518 5.72474 13.9825 5.594C13.8132 5.46327 13.6082 5.38375 13.3928 5.36533L10.2488 5.1011L9.01878 2.25304C8.93417 2.05936 8.79276 1.89416 8.61219 1.77803C8.43162 1.6619 8.21987 1.59998 8.00333 1.59998C7.78679 1.59998 7.57503 1.6619 7.39446 1.77803C7.21389 1.89416 7.07249 2.05936 6.98788 2.25304L5.75621 5.1011L2.61222 5.36533C2.39566 5.38275 2.18923 5.46193 2.01881 5.59293C1.8484 5.72393 1.72159 5.90092 1.65431 6.10168C1.58703 6.30245 1.58227 6.51805 1.64062 6.72143C1.69897 6.92482 1.81785 7.10693 1.98232 7.24492L4.37175 9.27082L3.6547 12.285C3.60552 12.4903 3.6197 12.7051 3.69547 12.9025C3.77123 13.1 3.90521 13.2714 4.08067 13.3952C4.25612 13.5191 4.46527 13.59 4.68195 13.599C4.89863 13.608 5.11324 13.5548 5.29895 13.4459L8.00167 11.8509L10.7044 13.4459C10.8901 13.5546 11.1046 13.6077 11.3212 13.5986C11.5378 13.5895 11.7468 13.5186 11.9222 13.3948C12.0975 13.2709 12.2315 13.0996 12.3072 12.9022C12.3829 12.7049 12.3972 12.4902 12.3481 12.285L11.631 9.27082L14.0205 7.24492C14.1845 7.10643 14.3028 6.92395 14.3605 6.72038C14.4182 6.51681 14.4127 6.30122 14.3448 6.10065ZM10.663 8.38489C10.5118 8.51277 10.3992 8.67829 10.3375 8.86366C10.2758 9.04902 10.2673 9.24723 10.3128 9.43697L10.941 12.0803L8.57256 10.6825C8.40055 10.5808 8.20305 10.5269 8.00167 10.5269C7.80029 10.5269 7.60279 10.5808 7.43079 10.6825L5.06232 12.0803L5.69057 9.43697C5.7361 9.24723 5.72755 9.04902 5.66583 8.86366C5.60412 8.67829 5.49158 8.51277 5.34032 8.38489L3.23936 6.60392L6.00497 6.37131C6.20524 6.35465 6.39696 6.28493 6.55928 6.16973C6.7216 6.05454 6.84828 5.89828 6.92555 5.71798L8.00167 3.22633L9.0778 5.71798C9.15506 5.89828 9.28175 6.05454 9.44407 6.16973C9.60639 6.28493 9.79811 6.35465 9.99838 6.37131L12.764 6.60392L10.663 8.38489Z"
        fill={color}
      />
    </svg>
  )
})

export default CollectNo
