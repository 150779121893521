import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import CSS from './index.module.scss'
import { Checkbox } from 'antd'
import antdUI from '@/antd-ui'
import classNames from 'classnames'
import Agreement from '@/components/Agreement'
import useMainStore from '@/store/main'

const Consent = forwardRef((props: any = {}, ref) => {
  const { isAgree = false, setIsAgree } = props
  const changeAgreementModalInfo = useMainStore(state => state.changeAgreementModalInfo)
  const [animation, setAnimation] = useState('')

  useImperativeHandle(ref, () => ({
    verifyState,
  }))

  const verifyState = () => {
    if (!isAgree) {
      setAnimation('animate__animated animate__headShake')
      setTimeout(() => {
        setAnimation('')
      }, 500)
      antdUI.messageApi.open({
        type: 'warning',
        content: '请勾选用户协议',
      })
      return
    }
    return true
  }

  return (
    <div className={classNames([CSS.agreement_box, animation, 'mt-4'])}>
      <div className={CSS.agreement_left}>
        <Checkbox checked={isAgree} onChange={e => setIsAgree?.(e.target.checked)}>
          <span style={{ paddingTop: '1px' }}>我已阅读并同意</span>
        </Checkbox>
      </div>
      <div className={CSS.agreement_right}>
        <span onClick={() => changeAgreementModalInfo({ open: true, title: '用户协议' })}>
          《用户协议》
        </span>
        <span onClick={() => changeAgreementModalInfo({ open: true, title: '隐私协议' })}>
          《隐私协议》
        </span>
      </div>
    </div>
  )
})

export default Consent
