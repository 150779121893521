import React, { memo, useEffect, useState } from 'react'
import { Modal } from 'antd'
import CSS from './index.module.scss'
import { getUserAgree, getPrivacyPolicy } from '@/api/modules/user'
import classNames from 'classnames'
import useMainStore from '@/store/main'

const Agreement = memo((props: any = {}) => {
  const { title, open } = useMainStore(state => state.agreementModalInfo)
  const changeAgreementModalInfo = useMainStore(state => state.changeAgreementModalInfo)
  const [contentInfo, setContentInfo] = useState({
    userAgree: '',
    privacyPolicy: '',
  })
  useEffect(() => {
    if (contentInfo.userAgree || contentInfo.privacyPolicy) {
      return
    }
    getUserAgree().then((res: any) => {
      setContentInfo(state => ({ ...state, userAgree: res.data?.content }))
    })
    getPrivacyPolicy().then((res: any) => {
      setContentInfo(state => ({ ...state, privacyPolicy: res.data?.content }))
    })
  }, [])
  return (
    <Modal
      title={<div className={CSS.title}>{title}</div>}
      className={CSS.agreement_modal}
      // width={'fit-content'}
      width={800}
      maskClosable={true}
      open={open}
      footer={null}
      zIndex={1001}
      onCancel={() => changeAgreementModalInfo({ open: false, title: '' })}
    >
      <div
        className={classNames([CSS.content, 'scrollbar_style'])}
        dangerouslySetInnerHTML={{
          __html: title === '用户协议' ? contentInfo.userAgree : contentInfo.privacyPolicy,
        }}
      ></div>
    </Modal>
  )
})

export default Agreement
