import React, { memo, useRef, useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import CSS from './index.module.scss'
import Options from '../Options'
import AddIcon2 from '@/assets/svg/AddIcon2'
import classNames from 'classnames'
import Textarea from '@/ui/Textarea'
import Close from '@/assets/svg/Close'
import TickIcon from '@/assets/svg/TickIcon'
import useMainStore from '@/store/main'

const Plan = forwardRef((_, ref) => {
  const OptionsRef = useRef<any>(null)
  const [planArrangeInfo, setPlanArrange] = useState({
    content: '',
    status: 0,
  })
  const [workSummaryInfo, setWorkSummary] = useState({
    content: '',
    status: 0,
  })

  const [currentKey, setCurrentKey] = useState<string | null>(null)

  const useTemplateInfo = useMainStore(state => state.useTemplateInfo)

  const PLAN_ARRANGE = 'planArrange'
  const WORK_SUMMARY = 'workSummary'

  const doneHandle = () => {
    if (currentKey === PLAN_ARRANGE) {
      if (planArrangeInfo.content.length) {
        setPlanArrange(state => ({ ...state, status: 2 }))
      } else {
        setPlanArrange(state => ({ ...state, status: 0 }))
      }
    }
    if (currentKey === WORK_SUMMARY) {
      if (workSummaryInfo.content.length) {
        setWorkSummary(state => ({ ...state, status: 2 }))
      } else {
        setWorkSummary(state => ({ ...state, status: 0 }))
      }
    }
  }

  const changePlan = (key: string) => {
    if (currentKey === key) return
    doneHandle()
    if (key === PLAN_ARRANGE) {
      setPlanArrange(state => ({ ...state, status: 1 }))
    }
    if (key === WORK_SUMMARY) {
      setWorkSummary(state => ({ ...state, status: 1 }))
    }
    setCurrentKey(key)
  }

  const closePlan = () => {
    if (currentKey === PLAN_ARRANGE) {
      setPlanArrange(state => ({ ...state, content: '', status: 0 }))
    }
    if (currentKey === WORK_SUMMARY) {
      setWorkSummary(state => ({ ...state, content: '', status: 0 }))
    }
    setCurrentKey(null)
  }

  const getData = () => {
    return {
      planArrange: planArrangeInfo.content,
      workSummary: workSummaryInfo.content,
      ...OptionsRef.current?.getData(),
    }
  }

  useImperativeHandle(ref, () => ({ getData }))

  useEffect(() => {
    if (useTemplateInfo.data) {
      const { planArrange, workSummary } = useTemplateInfo.data
      setCurrentKey(null)
      if (planArrange) {
        setPlanArrange({ content: planArrange, status: 2 })
      } else {
        setPlanArrange({ content: '', status: 0 })
      }
      if (workSummary) {
        setWorkSummary({ content: workSummary, status: 2 })
      } else {
        setWorkSummary({ content: '', status: 0 })
      }
    }
  }, [useTemplateInfo])

  return (
    <div className={CSS.wrapper}>
      <div className={CSS.top_box}>
        <Options ref={OptionsRef} />
        <div className={CSS.plan_box}>
          <div
            className={classNames([
              CSS.plan_btn,
              planArrangeInfo.status === 1 && CSS.plan_btn_status1,
              planArrangeInfo.status === 2 && CSS.plan_btn_status2,
              'flex items-center',
            ])}
            onClick={() => changePlan(PLAN_ARRANGE)}
          >
            <span>计划安排</span>
            <div className="flex items-center">{planArrangeInfo.status === 0 && <AddIcon2 />}</div>
            <div className="flex items-center" onClick={closePlan}>
              {planArrangeInfo.status === 1 && <Close />}
            </div>
            <div className="flex items-center">{planArrangeInfo.status === 2 && <TickIcon />}</div>
          </div>
          <div
            className={classNames([
              CSS.plan_btn,
              workSummaryInfo.status === 1 && CSS.plan_btn_status1,
              workSummaryInfo.status === 2 && CSS.plan_btn_status2,
              'ml-3 flex items-center',
            ])}
            onClick={() => changePlan(WORK_SUMMARY)}
          >
            <span>工作总结</span>
            <div className="flex items-center">{workSummaryInfo.status === 0 && <AddIcon2 />}</div>
            <div className="flex items-center" onClick={closePlan}>
              {workSummaryInfo.status === 1 && <Close />}
            </div>
            <div className="flex items-center">{workSummaryInfo.status === 2 && <TickIcon />}</div>
          </div>
        </div>
      </div>
      {currentKey && (
        <div className="mt-2">
          {currentKey === 'planArrange' ? (
            <Textarea
              placeholder="简单描述你的计划安排"
              value={planArrangeInfo.content}
              maxLength={150}
              onChange={(e: any) =>
                setPlanArrange(state => ({ ...state, content: e.target.value }))
              }
            />
          ) : (
            <Textarea
              placeholder="简单描述你的工作总结"
              value={workSummaryInfo.content}
              maxLength={150}
              onChange={(e: any) =>
                setWorkSummary(state => ({ ...state, content: e.target.value }))
              }
            />
          )}
        </div>
      )}
    </div>
  )
})

export default memo(Plan)
