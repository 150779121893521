import React, { memo } from 'react'
import { Input } from 'antd'
import CSS from './index.module.scss'
import classNames from 'classnames'

const Textarea = memo((props: any = {}) => {
  const {
    value = '',
    placeholder = '请输入内容',
    maxLength = -1,
    className = '',
    inputStyle = {},
    style = {},
    disabled = false,
    onChange,
    onKeyDown,
    showCount,
  } = props || {}
  return (
    <div className={CSS.input_box} style={style}>
      <Input.TextArea
        autoSize={{ minRows: 1, maxRows: 3 }}
        className={classNames([
          CSS.input,
          className,
          disabled && CSS.disabled_text,
          'scrollbar_style',
        ])}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
        placeholder={placeholder}
        maxLength={maxLength}
        showCount={showCount}
        style={inputStyle}
        disabled={disabled}
      />
    </div>
  )
})

export default Textarea
