import React, { memo } from 'react'

const AddIcon = memo((props: any = {}) => {
  const { color = '#666666' } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M7.33337 7.83334V5.16668H8.66671V7.83334H11.3334V9.16668H8.66671V11.8333H7.33337V9.16668H4.66671V7.83334H7.33337ZM8.00004 15.1667C4.31804 15.1667 1.33337 12.182 1.33337 8.50001C1.33337 4.81801 4.31804 1.83334 8.00004 1.83334C11.682 1.83334 14.6667 4.81801 14.6667 8.50001C14.6667 12.182 11.682 15.1667 8.00004 15.1667ZM8.00004 13.8333C9.41453 13.8333 10.7711 13.2714 11.7713 12.2712C12.7715 11.2711 13.3334 9.9145 13.3334 8.50001C13.3334 7.08552 12.7715 5.72897 11.7713 4.72877C10.7711 3.72858 9.41453 3.16668 8.00004 3.16668C6.58555 3.16668 5.229 3.72858 4.2288 4.72877C3.22861 5.72897 2.66671 7.08552 2.66671 8.50001C2.66671 9.9145 3.22861 11.2711 4.2288 12.2712C5.229 13.2714 6.58555 13.8333 8.00004 13.8333Z"
        fill={color}
      />
    </svg>
  )
})

export default AddIcon
