import React, { memo } from 'react'

const CareerEmptyIcon = memo(() => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="139"
      height="121"
      viewBox="0 0 139 121"
      fill="none"
    >
      <path
        d="M69.348 105.267C97.0749 105.267 119.553 82.66 119.553 54.6751C119.553 26.6902 96.9759 4.08319 69.348 4.08319C41.6212 4.08319 19.1426 26.6902 19.1426 54.6751C19.1426 82.66 41.6212 105.267 69.348 105.267Z"
        fill="#EAEEF9"
      />
      <path
        d="M116.286 21.5116C118.528 21.5116 120.346 19.6835 120.346 17.4284C120.346 15.1733 118.528 13.3452 116.286 13.3452C114.044 13.3452 112.226 15.1733 112.226 17.4284C112.226 19.6835 114.044 21.5116 116.286 21.5116Z"
        fill="#EAEEF9"
      />
      <path
        d="M122.227 5.57706C123.759 5.57706 125 4.32859 125 2.78853C125 1.24847 123.759 0 122.227 0C120.696 0 119.455 1.24847 119.455 2.78853C119.455 4.32859 120.696 5.57706 122.227 5.57706Z"
        fill="#EAEEF9"
      />
      <path
        d="M21.8164 21.412C23.3477 21.412 24.5891 20.1635 24.5891 18.6234C24.5891 17.0834 23.3477 15.8349 21.8164 15.8349C20.2851 15.8349 19.0437 17.0834 19.0437 18.6234C19.0437 20.1635 20.2851 21.412 21.8164 21.412Z"
        fill="#EAEEF9"
      />
      <path
        d="M10.1493 81.6921C12.9931 81.6921 15.2986 79.3735 15.2986 76.5134C15.2986 73.6532 12.9931 71.3347 10.1493 71.3347C7.30541 71.3347 5 73.6532 5 76.5134C5 79.3735 7.30541 81.6921 10.1493 81.6921Z"
        fill="#EAEEF9"
      />
      <g filter="url(#filter0_d_3139_3897)">
        <path
          d="M109.416 87.6859H29.28C25.5956 87.6859 22.6021 84.6752 22.6021 80.9697V29.3249C22.6021 25.6194 25.5956 22.6087 29.28 22.6087H109.416C113.1 22.6087 116.094 25.6194 116.094 29.3249V81.2013C115.864 84.6752 112.87 87.6859 109.416 87.6859Z"
          fill="url(#paint0_linear_3139_3897)"
        />
      </g>
      <rect x="37.1893" y="24.9888" width="13.8775" height="5.13178" rx="1" fill="#D8DDEB" />
      <rect x="86.9337" y="24.9888" width="13.8775" height="5.13178" rx="1" fill="#D8DDEB" />
      <g filter="url(#filter1_d_3139_3897)">
        <path
          d="M48.0837 13.345H40.1819C39.6296 13.345 39.1819 13.7927 39.1819 14.345V26.3527C39.1819 26.905 39.6296 27.3527 40.1819 27.3527H48.0837C48.636 27.3527 49.0837 26.905 49.0837 26.3527V14.345C49.0837 13.7927 48.636 13.345 48.0837 13.345Z"
          fill="url(#paint1_linear_3139_3897)"
        />
      </g>
      <g filter="url(#filter2_d_3139_3897)">
        <path
          d="M97.8281 13.345H89.9263C89.374 13.345 88.9263 13.7927 88.9263 14.345V26.3527C88.9263 26.905 89.374 27.3527 89.9263 27.3527H97.8281C98.3804 27.3527 98.8281 26.905 98.8281 26.3527V14.345C98.8281 13.7927 98.3804 13.345 97.8281 13.345Z"
          fill="url(#paint2_linear_3139_3897)"
        />
      </g>
      <path
        d="M51.6167 58.0421C55.8135 58.0421 59.2158 54.6204 59.2158 50.3996C59.2158 46.1787 55.8135 42.7571 51.6167 42.7571C47.4198 42.7571 44.0176 46.1787 44.0176 50.3996C44.0176 54.6204 47.4198 58.0421 51.6167 58.0421Z"
        stroke="#939DAE"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.4124 70.5511C39.4124 63.8349 44.939 58.2767 51.617 58.2767C58.2949 58.2767 63.8215 63.8349 63.8215 70.5511"
        stroke="#939DAE"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.4866 49.0101H101.126"
        stroke="#939DAE"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.4866 61.516H86.1581"
        stroke="#939DAE"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.6277 97.447C84.3083 98.812 83.8293 100.257 83.2704 101.462C81.7534 104.433 79.3581 106.762 76.404 108.287C73.37 109.813 69.7771 110.455 66.1842 109.652C57.721 107.886 52.2918 99.5347 54.0483 91.0231C55.8048 82.5115 64.0285 76.971 72.4917 78.8178C75.5257 79.4602 78.1605 80.9859 80.3961 83.0736C84.1486 86.8476 85.7455 92.3079 84.6277 97.447Z"
        fill="url(#paint3_linear_3139_3897)"
      />
      <path
        d="M74.35 92.8738H70.7469L70.7674 89.2707C70.7715 88.5501 70.2147 87.9095 69.414 87.9095C68.6934 87.9095 68.0496 88.47 68.045 89.2707L68.0245 92.8738H64.4214C63.7007 92.8738 63.057 93.4343 63.0524 94.235C63.0478 95.0357 63.6051 95.5962 64.4058 95.5962H68.009L67.9885 99.1993C67.9844 99.9199 68.5412 100.56 69.3419 100.56C70.0625 100.56 70.7063 99.9999 70.7108 99.1993L70.7314 95.5962H74.3345C75.0552 95.5962 75.6989 95.0357 75.7035 94.235C75.7081 93.4343 75.0707 92.8738 74.35 92.8738Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_3139_3897"
          x="0.602051"
          y="11.6087"
          width="137.492"
          height="109.077"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.303734 0 0 0 0 0.473054 0 0 0 0 0.68 0 0 0 0.27 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3139_3897" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3139_3897"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3139_3897"
          x="17.1819"
          y="2.34503"
          width="53.9019"
          height="58.0076"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3139_3897" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3139_3897"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_3139_3897"
          x="66.9263"
          y="2.34503"
          width="53.9019"
          height="58.0076"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3139_3897" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3139_3897"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3139_3897"
          x1="69.3175"
          y1="21.1034"
          x2="69.3175"
          y2="88.3874"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.9964" stopColor="#F6F7FA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3139_3897"
          x1="44.1296"
          y1="13.021"
          x2="44.1296"
          y2="27.5037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3139_3897"
          x1="93.874"
          y1="13.021"
          x2="93.874"
          y2="27.5037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3139_3897"
          x1="53.7039"
          y1="94.2346"
          x2="84.9836"
          y2="94.2346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  )
})

export default CareerEmptyIcon
