import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import antdUI from '@/antd-ui'
import cache from '@/utils/cache'
import useUserStore from './user'

const store = (set: any, get: any) => ({
  loginSwitch: false,
  emailModalSwitch: false,
  sendEmailModalSwitch: false,
  careerModalSwitch: false,
  editReportModalSwitch: false,
  sideInfo: {
    left: false,
    right: false,
  },
  templateModalInfo: {
    open: false,
    data: {},
    id: null,
    title: '',
  },
  useTemplateInfo: {
    data: null,
    id: null,
  },
  agreementModalInfo: {
    open: false,
    title: '',
  },
  changeSwitchHandle(key: string, value: boolean) {
    set({
      [key]: value,
    })
  },
  changeAgreementModalInfo(agreementModalInfo: any) {
    set(() => ({
      agreementModalInfo,
    }))
  },
  changeTemplateModalInfo(state: any) {
    if (typeof state === 'function') {
      const { templateModalInfo } = get()
      state = state(templateModalInfo)
    }
    set(() => ({
      templateModalInfo: state,
    }))
  },
  changeUseTemplateInfo(useTemplateInfo: any) {
    set(() => ({
      useTemplateInfo,
    }))
  },
  changeSideInfo(state: any) {
    if (typeof state === 'function') {
      const { sideInfo } = get()
      state = state(sideInfo)
    }
    set({
      sideInfo: state,
    })
  },
  setToken(token: string) {
    set(() => ({
      token,
    }))
  },
  outLogin() {
    const { isLogin, setToken } = useUserStore.getState()
    if (isLogin()) {
      antdUI.messageApi.open({
        type: 'error',
        content: '登录过期，请重新登录',
      })
      setToken('')
      setTimeout(() => {
        cache.clearCache()
        window.location.reload()
      }, 1000)
    }
  },
})

function partialize(state: any) {
  // 过滤字段
  const filterArr = [
    'loginSwitch',
    'agreementSwitch',
    'emailModalSwitch',
    'sendEmailModalSwitch',
    'templateModalInfo',
    'useTemplateInfo',
    'editReportModalSwitch',
  ]
  return Object.fromEntries(Object.entries(state).filter(([key]) => !filterArr.includes(key)))
}

const useMainStore = create(persist(store, { name: 'main-state', partialize }))

export default useMainStore
