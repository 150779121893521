import useMainStore from '@/store/main'
import BJRequest from './request'
import { BASE_URL, TIME_OUT } from './request/config'
import NProgress from '@/config/nprogress'
import useUserStore from '@/store/user'

const request = new BJRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  hooks: {
    requestHook(config) {
      if ((config.headers as any).isLoad || (config.headers as any).isLoad === void 0) {
        NProgress.start()
      }
      // console.log('config-----------: ', config)
      const token = useUserStore.getState().token
      if (token && config.headers) {
        config.headers.token = token
      }
      if (config.headers) {
        config.headers.ctype = 'pc_2'
      }
      return config
    },
    requestHookCatch(err) {
      return err
    },
    responseHook(config) {
      NProgress.done()
      return config
    },
    responseHookCatch(err) {
      // console.log('err: ', err)
      if ([401].includes((err as any).response.status)) {
        const mainStore = useMainStore.getState()
        mainStore.outLogin()
      }
      NProgress.done()
      return err
    },
  },
})

export { request }
