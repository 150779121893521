import { ConfigProvider, message, notification } from 'antd'
import { RouterComponent } from './router'
import { HashRouter } from 'react-router-dom'
// import AuthRouter from './router/AuthRouter'
import { useInternationalization } from './hooks/useInternationalization'
import antdUI from './antd-ui'
import GlobalPopUp from './components/GlobalPopUp'

function App() {
  const { antdLocale } = useInternationalization()
  const [notificationApi, notContextHolder] = notification.useNotification()
  const [messageApi, msgContextHolder] = message.useMessage()
  if (!antdUI.notificationApi) {
    ;(antdUI.notificationApi as any) = notificationApi
  }
  if (!antdUI.messageApi) {
    ;(antdUI.messageApi as any) = messageApi
  }
  return (
    <ConfigProvider
      locale={antdLocale}
      theme={{
        token: {
          borderRadius: 8,
        },
      }}
    >
      {notContextHolder}
      {msgContextHolder}
      <GlobalPopUp />
      {/* <Demo1 /> */}
      <HashRouter>
        <RouterComponent />
      </HashRouter>
    </ConfigProvider>
  )
}

export default App
