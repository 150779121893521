import React, { memo, useState } from 'react'
import { Avatar, Button, Modal } from 'antd'
import { RightOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import CSS from './right.module.scss'
import useUserStore from '@/store/user'
import DefaultAvatar from '@/assets/svg/DefaultAvatar'
import classNames from 'classnames'
import ToolIcon from '@/assets/svg/ToolIcon'
import ArrowsRight1 from '@/assets/svg/ArrowsRight1'
import Card from '@/ui/Card'
import Cell from '@/ui/Cell'
import MsgIcon from '@/assets/svg/MsgIcon'
import TemplateBox from './cpns/TemplateBox'
import useMainStore from '@/store/main'
import cache from '@/utils/cache'

const Right = memo(() => {
  const [modal, contextHolder] = Modal.useModal()
  const { token, userInfo, isLogin } = useUserStore(state => ({
    token: state.token,
    userInfo: state.userInfo,
    isLogin: state.isLogin,
  }))

  const { changeSwitchHandle } = useMainStore(state => ({
    changeSwitchHandle: state.changeSwitchHandle,
  }))

  const sideInfo = useMainStore(state => state.sideInfo)

  const openModal = (key: string) => {
    if (!isLogin()) {
      changeSwitchHandle('loginSwitch', true)
      return
    }
    changeSwitchHandle(key, true)
  }

  const showConfirm = () => {
    modal.confirm({
      title: '温馨提示',
      icon: <ExclamationCircleOutlined />,
      content: '是否退出登录？',
      onOk() {
        console.log('OK')
        cache.clearCache()
        location.reload()
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  const UserInfoElement = (
    <div className={classNames([CSS.userinfo, 'flex justify-between items-center'])}>
      <div className={classNames([CSS.info_left, 'flex items-center'])}>
        <div className={classNames([CSS.avatar, 'flex items-center'])}>
          <DefaultAvatar />
          {/* <Avatar size={32} icon={<DefaultAvatar />} /> */}
        </div>
        <div className={CSS.username}>{userInfo?.username}</div>
      </div>
      <div className={CSS.info_right}>
        <span onClick={showConfirm}>退出登录</span>
      </div>
    </div>
  )

  return (
    <div className={classNames([CSS.wrapper, sideInfo.right ? CSS.side_packup : CSS.side_unfold])}>
      {contextHolder}
      <div className="px-4">
        {isLogin() ? (
          UserInfoElement
        ) : (
          <div className={CSS.login_btn}>
            <Button
              type="primary"
              className="aiq-btn-primary"
              onClick={() => changeSwitchHandle('loginSwitch', true)}
            >
              注册/登录账号
            </Button>
          </div>
        )}
        <Card>
          <Cell
            leftIcon={<ToolIcon />}
            label="我的职业"
            rightContent={`${isLogin() ? `已添加${userInfo?.career_num}个` : '请先登录'}`}
            onClick={() => openModal('careerModalSwitch')}
          />
          <Cell
            leftIcon={<MsgIcon />}
            label="我的邮箱"
            rightContent={
              <div className={classNames([CSS.tag, userInfo?.email && CSS.tab_activated])}>
                {userInfo?.email ? '已设置' : '未设置'}
              </div>
            }
            showRightArrows={false}
            onClick={() => openModal('emailModalSwitch')}
          />
        </Card>
      </div>
      <TemplateBox />
    </div>
  )
})

export default Right
