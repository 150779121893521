import React, { memo } from 'react'

const UnfoldIcon = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7" fill="none">
      <path d="M4.5 7L0.602886 1.75L8.39711 1.75L4.5 7Z" fill="#C4C4C4" />
    </svg>
  )
})

export default UnfoldIcon
