import React from 'react'
import { Navigate } from 'react-router-dom'
import MainLayout from '@/main-layout/index'
// import HomePage from '@/pages/HomePage'

// const Assistant = React.lazy(() => import('@/pages/Assistant'))
// const Creation = React.lazy(() => import('@/pages/Creation'))

export const defaultRouter = [
  {
    path: '/',
    element: <MainLayout />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
    meta: {
      title: 'AIQ',
    },
  },
]
