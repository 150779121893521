import React, { memo } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import CSS from './index.module.scss'
import Input from '@/ui/Input'

import * as SmsAPI from '@/api/modules/sms'
import { useCaptcha } from '@/hooks/useCaptcha'
import { useRequest } from '@/hooks/useRequest'
import { Spin } from 'antd'
import { verifyMobile } from '@/utils'
import antdUI from '@/antd-ui'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const GetCaptcha = memo((props: any = {}) => {
  const { form, codeEvent = 'mobilelogin', mt = 'mt-4' } = props
  const loginCaptcha = useCaptcha()
  const captchaReq = useRequest(SmsAPI.send)

  const getCaptcha = () => {
    if (!verifyMobile(form.mobile)) {
      antdUI.messageApi.open({
        type: 'warning',
        content: '请输入正确的手机号',
      })
      return
    }
    captchaReq.run(
      { mobile: form.mobile, event: codeEvent },
      {
        onSuccess(res) {
          loginCaptcha.getCode()
        },
      },
      {
        code: 1,
      }
    )
  }

  return (
    <div>
      <Input
        value={form.mobile}
        type="number"
        maxLength={11}
        placeholder="请输入手机号"
        onChange={(e: any) => props.onChange(e.target.value, 'mobile')}
      />
      <Input
        className={`${mt}`}
        value={form.captcha}
        maxLength={6}
        placeholder="请输入验证码"
        onChange={(e: any) => props.onChange(e.target.value, 'captcha')}
        rightChild={
          captchaReq.loading ? (
            <Spin indicator={antIcon} />
          ) : loginCaptcha.isSetup ? (
            <div className={CSS.time_box}>{loginCaptcha.code}s</div>
          ) : (
            <div className={CSS.code_box} onClick={getCaptcha}>
              {loginCaptcha.isInit ? '重新获取' : '获取验证码'}
            </div>
          )
        }
      />
    </div>
  )
})

export default GetCaptcha
