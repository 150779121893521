import React, { memo } from 'react'
import CSS from './index.module.scss'
import EditIcon from '@/assets/svg/EditIcon'
import CopyIcon2 from '@/assets/svg/CopyIcon2'
import CollectNo from '@/assets/svg/CollectNo'
import DeleteIcon from '@/assets/svg/DeleteIcon'
import moment from 'moment'
import { copyTool } from '@/utils/tool'
import CollectYes from '@/assets/svg/CollectYes'
import { Popconfirm } from 'antd'
import TextIcon from '@/assets/svg/TextIcon'
import classNames from 'classnames'

const Item = memo((props: any = {}) => {
  const { item, showDel = true, showCollect = true } = props
  return (
    <div className={CSS.list_it}>
      <div className={CSS.it_top}>
        <div className={CSS.it_title}>{item.career}</div>
        <div className={CSS.it_time}>
          {moment(item.createtime * 1000).format('YYYY年MM月DD日 HH:mm')}
        </div>
      </div>
      <div className={CSS.it_center}>
        <div className={CSS.it_content}>{item.answer ? item.answer : '内容为空...'}</div>
      </div>
      <div className={CSS.it_footer}>
        <div className={classNames([CSS.footer_it, 'icon_formal_activate'])} onClick={props.onView}>
          <TextIcon color="#666666" />
          <span className={CSS.text}>查看</span>
        </div>
        <div
          className={classNames([CSS.footer_it, 'icon_formal_activate'])}
          onClick={() => {
            copyTool(item.answer ?? '内容为OK')
            props.onCopy?.()
          }}
        >
          <CopyIcon2 color="#666666" />
          <span className={CSS.text}>复制</span>
        </div>
        {showCollect && (
          <div
            className={classNames([CSS.footer_it, item.is_collect === 0 && 'icon_formal_activate'])}
            onClick={props.onCollect}
          >
            {item.is_collect === 0 ? <CollectNo color="#666666" /> : <CollectYes />}
            <span className={CSS.text}>{item.is_collect === 0 ? '收藏' : '已收藏'}</span>
          </div>
        )}
        {showDel && (
          <Popconfirm
            title="是否删除该记录？"
            description={
              props.reportId === item.id ? (
                <>
                  <div>您正在操作的是预览中内容，</div>
                  <div>删除该记录时，预览中的所有信息也将被清空。</div>
                </>
              ) : (
                ''
              )
            }
            placement="top"
            okText="删除"
            cancelText="取消"
            onConfirm={props.onDelete}
          >
            <div className={classNames([CSS.footer_it, 'icon_del_activate'])}>
              <DeleteIcon color="#666666" />
              <span className={CSS.text}>删除</span>
            </div>
          </Popconfirm>
        )}
      </div>
    </div>
  )
})

export default Item
