import React, { memo } from 'react'
import CSS from './index.module.scss'
import { Button } from 'antd'
import classNames from 'classnames'
import { copyTool, messageTool } from '@/utils/tool'
import EmailIcon from '@/assets/svg/EmailIcon'
import CopyIcon2 from '@/assets/svg/CopyIcon2'
import CollectNo from '@/assets/svg/CollectNo'
import EditIcon from '@/assets/svg/EditIcon'
import TextIcon from '@/assets/svg/TextIcon'
import { useRequest } from '@/hooks/useRequest'
import * as UserAPI from '@/api/modules/user'
import useMainStore from '@/store/main'
import useChatStore from '@/store/chat'
import CollectYes from '@/assets/svg/CollectYes'
import useListStore from '@/store/list'

const DoneBtn = memo((props: any = {}) => {
  const changeSwitchHandle = useMainStore(state => state.changeSwitchHandle)
  const { isCollect, changeIsCollect } = useChatStore(state => ({
    isCollect: state.isCollect,
    changeIsCollect: state.changeIsCollect,
  }))
  const {
    historyListInfo,
    getHistoryList,
    collectListInfo,
    getCollectList,
    draftsListInfo,
    getdraftsList,
  } = useListStore(state => ({
    historyListInfo: state.historyListInfo,
    getHistoryList: state.getHistoryList,
    collectListInfo: state.collectListInfo,
    getCollectList: state.getCollectList,
    draftsListInfo: state.draftsListInfo,
    getdraftsList: state.getdraftsList,
  }))
  const collectionReq = useRequest(UserAPI.collection)
  const collectHandle = async () => {
    if (props.reportId === null) {
      return messageTool('报告id不能为空', 'error')
    }
    const [err, res] = await collectionReq.run([props.reportId])
    if (err) return
    messageTool(res.msg)
    if (res.msg === '取消收藏') {
      changeIsCollect(false)
    }
    if (res.msg === '收藏成功') {
      changeIsCollect(true)
    }
    getHistoryList(historyListInfo.page, historyListInfo.size)
    getCollectList(collectListInfo.page, collectListInfo.size)
    getdraftsList(draftsListInfo.page, draftsListInfo.size)
  }

  return (
    <div className={CSS.wrapper}>
      <Button
        type="primary"
        ghost
        className={classNames([CSS.btn, 'aiq-btn-ghost'])}
        onClick={props.onStart}
      >
        <TextIcon />
        <span className={CSS.text}>重新生成</span>
      </Button>
      <Button
        type="primary"
        ghost
        className={classNames([CSS.btn, 'aiq-btn-ghost'])}
        onClick={() => changeSwitchHandle('editReportModalSwitch', true)}
      >
        <EditIcon color="#4361FF" />
        <span className={CSS.text}>编辑内容</span>
      </Button>
      <Button
        type="primary"
        ghost
        className={classNames([CSS.btn, 'aiq-btn-ghost'])}
        onClick={collectHandle}
        loading={collectionReq.loading}
      >
        {!collectionReq.loading && isCollect ? <CollectYes /> : <CollectNo />}
        <span className={CSS.text}>{isCollect ? '已收藏' : '收藏'}</span>
      </Button>
      <Button
        type="primary"
        ghost
        className={classNames([CSS.btn, 'aiq-btn-ghost'])}
        onClick={() => copyTool(props.content)}
      >
        <CopyIcon2 />
        <span className={CSS.text}>复制</span>
      </Button>
      <Button
        type="primary"
        className={classNames([CSS.btn, CSS.email_btn, 'aiq-btn-primary'])}
        onClick={() => changeSwitchHandle('sendEmailModalSwitch', true)}
      >
        <EmailIcon />
        <span className={CSS.text}>转发到邮箱</span>
      </Button>
    </div>
  )
})

export default DoneBtn
