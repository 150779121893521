import React, { memo, useState, forwardRef, useImperativeHandle, useEffect } from 'react'
import CSS from './index.module.scss'
import Textarea from '@/ui/Textarea'
import useMainStore from '@/store/main'

const WorkContent = forwardRef((props: any = {}, ref) => {
  const { templateModalInfo, useTemplateInfo } = useMainStore(state => ({
    templateModalInfo: state.templateModalInfo,
    useTemplateInfo: state.useTemplateInfo,
  }))
  const [value, setValue] = useState('')

  useEffect(() => {
    if (useTemplateInfo.data) {
      setValue((useTemplateInfo.data as any).workContent)
    }
  }, [useTemplateInfo])

  const getData = () => {
    return {
      workContent: value,
    }
  }
  useImperativeHandle(ref, () => ({ getData }))
  return (
    <div className={CSS.wrapper}>
      <div className={CSS.top_box}>
        <div className={CSS.top_left}>工作内容</div>
        <div className={CSS.top_right}>
          {templateModalInfo.id !== null && <span onClick={props.onUpdateDrafts}>保存原模板</span>}
          <span onClick={props.onAddDrafts}>存为新模板</span>
        </div>
      </div>
      <Textarea
        className="mt-2"
        placeholder="简单描述你的工作内容"
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
      />
    </div>
  )
})

export default memo(WorkContent)
