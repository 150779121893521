import React, { memo } from 'react'

const ArrowsLeft1 = memo((props: any = {}) => {
  const { color = '#404040', size = 24 } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
    >
      <path
        d="M15.375 5.25L8.625 12L15.375 18.75"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})

export default ArrowsLeft1
