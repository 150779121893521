import React, { memo, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import CSS from './index.module.scss'
import { Popover } from 'antd'
import UnfoldIcon from '@/assets/svg/UnfoldIcon'
import classNames from 'classnames'
import useListStore from '@/store/list'
import useUserStore from '@/store/user'
import useMainStore from '@/store/main'

const generateNumList = [
  {
    name: '100字数',
    id: 1,
  },
  {
    name: '200字数',
    id: 2,
  },
  {
    name: '300字数',
    id: 3,
  },
  {
    name: '500字数',
    id: 4,
  },
  {
    name: '1000字数',
    id: 5,
  },
  {
    name: '1500字数',
    id: 6,
  },
]

const reportFormList = [
  {
    name: '日报',
    id: 1,
  },
  {
    name: '周报',
    id: 2,
  },
  {
    name: '月报',
    id: 3,
  },
]

function SelectedItem(props: any = {}) {
  return (
    <div className={CSS.menu}>
      <div className={CSS.menu_it}>{props.title}</div>
      {props.list.map((v: any, i: number) => (
        <div
          className={classNames([
            CSS.menu_it,
            props.currentName === v.name && CSS.menu_it_activated,
          ])}
          key={v.id}
          onClick={() => props.onChange(v, i)}
        >
          {v.name}
        </div>
      ))}
    </div>
  )
}

function SelectedItem2(props: any = {}) {
  const { existList = [] } = props
  return (
    <div className={classNames([CSS.menu, 'scrollbar_style'])}>
      <div className={CSS.menu_it}>{props.title}</div>
      {props.list.map((v: any, i: number) => {
        return (
          <div
            className={classNames([
              CSS.menu_it,
              CSS.menu_it2,
              props.currentName === v.name && CSS.menu_it_activated,
            ])}
            key={v.id}
            onClick={() => props.onChange(v, i)}
          >
            <div className={CSS.menu_it_text}>{v.name}</div>
            {existList.includes(v.name) ? (
              <div
                className={classNames([
                  CSS.menu_it_pre,
                  existList.includes(v.name) && CSS.menu_it_pre_activate,
                ])}
              >
                您的职业
              </div>
            ) : (
              <div className={classNames([CSS.menu_it_pre])}>预设职业</div>
            )}
          </div>
        )
      })}
    </div>
  )
}

const Options = forwardRef((props: any = {}, ref) => {
  const [generateNumInfo, setGenerateNumInfo] = useState({
    open: false,
    value: '300字数',
  })
  const [reportFormInfo, setReportFormInfo] = useState({
    open: false,
    value: '日报',
  })
  const [careerInfo, setCareerInfo] = useState({
    open: false,
    value: '项目助理',
  })

  const { careerListInfo, myCareerListInfo, getCareerList } = useListStore()
  const token = useUserStore(state => state.token)
  const userInfo = useUserStore(state => state.userInfo)
  const useTemplateInfo = useMainStore(state => state.useTemplateInfo)

  const getData = () => {
    return {
      generateNum: generateNumInfo.value,
      reportForm: reportFormInfo.value,
      career: careerInfo.value,
      careerId: careerListInfo.list.find(v => v.name === careerInfo.value)?.id,
    }
  }

  useImperativeHandle(ref, () => ({
    getData,
  }))

  useEffect(() => {
    if (useTemplateInfo.data) {
      const { generateNum, reportForm, career } = useTemplateInfo.data
      setGenerateNumInfo(state => ({ ...state, value: generateNum }))
      setReportFormInfo(state => ({ ...state, value: reportForm }))
      setCareerInfo(state => ({ ...state, value: career }))
    }
  }, [useTemplateInfo])

  useEffect(() => {
    if (token) {
      getCareerList()
    }
  }, [token])

  useEffect(() => {
    if (careerListInfo.list.length && userInfo) {
      const item = careerListInfo.list.find(v => v.name === userInfo.career)
      setCareerInfo(state => ({ ...state, value: item?.name ?? '项目经理' }))
    }
  }, [careerListInfo.list, userInfo])

  return (
    <div className={CSS.wrapper}>
      {/* 字数选项 */}
      <Popover
        overlayClassName={CSS.popover_class}
        content={
          <SelectedItem
            list={generateNumList}
            currentName={generateNumInfo.value}
            title="生成字数"
            onChange={(v: any) => setGenerateNumInfo({ value: v.name, open: false })}
          />
        }
        placement="bottom"
        trigger="click"
        open={generateNumInfo.open}
        onOpenChange={(e: boolean) => setGenerateNumInfo(state => ({ ...state, open: e }))}
      >
        <div className={classNames([CSS.it_box, 'flex', 'items-center'])}>
          <span className={CSS.it_text}>{generateNumInfo.value}</span>
          <UnfoldIcon />
        </div>
      </Popover>
      {/* 职业选项 */}
      <Popover
        overlayClassName={CSS.popover_class}
        content={
          <SelectedItem2
            list={careerListInfo.list}
            existList={myCareerListInfo.list.map((v: any) => v.career)}
            currentName={careerInfo.value}
            title="职业"
            onChange={(v: any) => setCareerInfo({ value: v.name, open: false })}
          />
        }
        placement="bottom"
        trigger="click"
        open={careerInfo.open}
        onOpenChange={(e: boolean) => setCareerInfo(state => ({ ...state, open: e }))}
      >
        <div className={classNames([CSS.it_box, 'flex items-center ml-3'])}>
          <span className={CSS.it_text}>{careerInfo.value}</span>
          <UnfoldIcon />
        </div>
      </Popover>
      {/* 报告类型选项 */}
      <Popover
        overlayClassName={CSS.popover_class}
        content={
          <SelectedItem
            list={reportFormList}
            currentName={reportFormInfo.value}
            title="生成格式"
            onChange={(v: any) => setReportFormInfo({ value: v.name, open: false })}
          />
        }
        placement="bottom"
        trigger="click"
        open={reportFormInfo.open}
        onOpenChange={(e: boolean) => setReportFormInfo(state => ({ ...state, open: e }))}
      >
        <div className={classNames([CSS.it_box, 'flex', 'items-center', 'ml-3'])}>
          <span className={CSS.it_text}>{reportFormInfo.value}</span>
          <UnfoldIcon />
        </div>
      </Popover>
    </div>
  )
})

export default memo(Options)
