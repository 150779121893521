import React, { memo, useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import CSS from './index.module.scss'
import useMainStore from '@/store/main'
import Input from '@/ui/Input'
import { useRequest } from '@/hooks/useRequest'
import * as DraftsAPI from '@/api/modules/drafts'
import { messageTool } from '@/utils/tool'
import useListStore from '@/store/list'
import useUserStore from '@/store/user'

const TemplateModal = memo(() => {
  const { templateModalInfo, changeTemplateModalInfo } = useMainStore(state => ({
    changeTemplateModalInfo: state.changeTemplateModalInfo,
    templateModalInfo: state.templateModalInfo,
  }))
  const getTemplateList = useListStore(state => state.getTemplateList)
  const [value, setValue] = useState('')
  const addDraftsReq = useRequest(DraftsAPI.addDrafts)
  const updateDraftsReq = useRequest(DraftsAPI.updateDrafts)

  const onOkHandle = async () => {
    if (templateModalInfo.title === '添加模板') {
      if (!value.trim()) {
        return messageTool('请输入模板名称', 'warning')
      }
      const [err, res] = await addDraftsReq.run({ ...templateModalInfo.data, title: value })
      if (err) return
      messageTool(res.msg)
      getTemplateList()
      changeTemplateModalInfo({ open: false, data: {}, id: res.data?.id })
      setValue('')
      return
    }
    const [err, res] = await updateDraftsReq.run({
      ...templateModalInfo.data,
      id: templateModalInfo.id,
    })
    if (err) return
    messageTool(res.msg)
    getTemplateList()
    changeTemplateModalInfo({ ...templateModalInfo, open: false, data: {} })
  }
  return (
    <Modal
      className={CSS.login_modal}
      width={320}
      title={templateModalInfo.title}
      open={templateModalInfo.open}
      onCancel={() => changeTemplateModalInfo({ ...templateModalInfo, open: false, data: {} })}
      footer={
        <div className={CSS.btn_box}>
          <Button
            type="primary"
            className="aiq-btn-primary"
            onClick={onOkHandle}
            loading={addDraftsReq.loading || updateDraftsReq.loading}
          >
            {templateModalInfo.title === '添加模板' ? '添加' : '保存'}
          </Button>
        </div>
      }
    >
      {templateModalInfo.title === '添加模板' && (
        <Input
          className="mt-4"
          placeholder="请输入模板名称"
          inputStyle={{ textAlign: 'center' }}
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
        />
      )}
    </Modal>
  )
})

export default TemplateModal
