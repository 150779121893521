import { request } from '..'
import type { IDataType } from '../type'

enum API {
  get = '/report/drafts/get',
  add = '/report/drafts/add',
  update = '/report/drafts/update',
  delete = '/report/drafts/delete',
  list = '/report/drafts/list',
}

/**
 * 获取草稿
 */
export function getDrafts(data = {}) {
  return request.post<IDataType>({
    url: API.get,
    data,
  })
}

/**
 * 添加草稿
 */
export function addDrafts(data = {}) {
  return request.post<IDataType>({
    url: API.add,
    data,
  })
}

/**
 * 更新草稿
 */
export function updateDrafts(data = {}) {
  return request.post<IDataType>({
    url: API.update,
    data,
  })
}

/**
 * 删除草稿
 */
export function deleteDrafts(data = {}) {
  return request.post<IDataType>({
    url: API.delete,
    data,
  })
}

/**
 * 草稿列表
 */
export function getDraftsList(data = {}) {
  return request.post<IDataType>({
    url: API.list,
    data,
  })
}
