import React, { memo } from 'react'

const TickIcon = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <g clipPath="url(#clip0_3543_464)">
        <path d="M1 6L4.5 9.5L11.5 2.5" stroke="#4361FF" strokeWidth="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_3543_464">
          <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
})

export default TickIcon
