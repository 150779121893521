import { request } from '..'
import type { IDataType } from '../type'

enum API {
  send = '/report/Sms/send',
}

/**
 * 发送短信验证码
 */
export function send(data = {}) {
  return request.post<IDataType>({
    url: API.send,
    data,
  })
}
