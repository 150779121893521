import React, { memo, useEffect, useState } from 'react'
import CSS from './left.module.scss'
import Tabs from './cpns/Tabs'
import classNames from 'classnames'
import useMainStore from '@/store/main'
import ScrollLazyLoading from '@/components/ScrollLazyLoading'
import Item from './cpns/Item'
import useListStore from '@/store/list'
import { useRequest } from '@/hooks/useRequest'
import * as UserAPI from '@/api/modules/user'
import { messageTool } from '@/utils/tool'
import { Pagination } from 'antd'
import List from './cpns/List'
import useChatStore from '@/store/chat'
import useUserStore from '@/store/user'

const items = [
  {
    key: '1',
    label: '生成记录',
  },
  {
    key: '2',
    label: '收藏记录',
  },
  {
    key: '3',
    label: '草稿箱',
  },
]

const Left = memo(() => {
  const [activeKey, setactiveKey] = useState(0)
  const sideInfo = useMainStore(state => state.sideInfo)

  const token = useUserStore(state => state.token)

  const getHistoryList = useListStore(state => state.getHistoryList)
  const getCollectList = useListStore(state => state.getCollectList)
  const getdraftsList = useListStore(state => state.getdraftsList)

  const historyListInfo = useListStore(state => state.historyListInfo)
  const collectListInfo = useListStore(state => state.collectListInfo)
  const draftsListInfo = useListStore(state => state.draftsListInfo)

  const {
    changeContent,
    changeReportId,
    changeType,
    changeIsCollect,
    reportId,
    changeInitChatInfo,
  } = useChatStore(state => ({
    changeContent: state.changeContent,
    changeReportId: state.changeReportId,
    changeType: state.changeType,
    changeIsCollect: state.changeIsCollect,
    reportId: state.reportId,
    changeInitChatInfo: state.changeInitChatInfo,
  }))

  const collectionReq = useRequest(UserAPI.collection)
  const deletRreportReq = useRequest(UserAPI.deletRreport)

  useEffect(() => {
    if (token) {
      getHistoryList()
      getCollectList()
      getdraftsList()
    }
  }, [token])

  const handleCollect = async (item: any) => {
    const [err, res] = await collectionReq.run([item.id])
    if (err) return
    messageTool(res.msg)
    getHistoryList(historyListInfo.page, historyListInfo.size)
    getCollectList(collectListInfo.page, collectListInfo.size)
  }

  const handleDelete = async (item: any, type: string) => {
    const [err, res] = await deletRreportReq.run({ ids: [item.id], type })
    if (err) return
    messageTool(res.msg)
    getHistoryList(historyListInfo.page, historyListInfo.size)
    getCollectList(collectListInfo.page, collectListInfo.size)
    getdraftsList(draftsListInfo.page, draftsListInfo.size)
    if (item.id === reportId) {
      changeInitChatInfo()
    }
  }

  const handleView = async (item: any, type = '') => {
    console.log('item: ', item)
    if (!item.answer) {
      messageTool('没有内容~', 'warning')
      return
    }
    changeContent(item.answer)
    changeReportId(item.id)
    changeType(type)
    if (item.is_collect === 1) {
      changeIsCollect(true)
    } else {
      changeIsCollect(false)
    }
  }

  const currentListInfo =
    activeKey === 0
      ? historyListInfo
      : activeKey === 1
      ? collectListInfo
      : activeKey === 2
      ? draftsListInfo
      : null

  const currentGetList =
    activeKey === 0
      ? getHistoryList
      : activeKey === 1
      ? getCollectList
      : activeKey === 2
      ? getdraftsList
      : () => ({})

  return (
    <div className={classNames([CSS.wrapper, sideInfo.left ? CSS.side_packup : CSS.side_unfold])}>
      <Tabs
        list={items}
        activeKey={activeKey}
        onChange={({ index }: any = {}) => setactiveKey(index)}
      />
      <List
        show={activeKey === 0}
        emptyText="暂无记录"
        list={historyListInfo.list}
        onNext={getHistoryList}
        renderItem={(v: any, i: number) => {
          return (
            <Item
              key={i}
              item={v}
              reportId={reportId}
              onView={() => handleView(v)}
              onCollect={() => handleCollect(v)}
              onDelete={() => handleDelete(v, 'history')}
            />
          )
        }}
      />
      <List
        show={activeKey === 1}
        emptyText="暂无收藏"
        list={collectListInfo.list}
        onNext={getCollectList}
        renderItem={(v: any, i: number) => {
          return (
            <Item
              key={i}
              item={v}
              showDel={false}
              reportId={reportId}
              onCollect={() => handleCollect(v)}
              onView={() => handleView(v)}
            />
          )
        }}
      />
      <List
        show={activeKey === 2}
        emptyText="暂无草稿"
        list={draftsListInfo.list}
        onNext={getdraftsList}
        renderItem={(v: any, i: number) => {
          return (
            <Item
              key={i}
              item={v}
              showCollect={false}
              reportId={reportId}
              onDelete={() => handleDelete(v, 'drafts')}
              onView={() => handleView(v, 'drafts')}
            />
          )
        }}
      />
      {currentListInfo?.total !== null && (currentListInfo as any).total > 20 && (
        <div className={CSS.pagination_box}>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={20}
            current={currentListInfo?.page}
            total={currentListInfo?.total}
            showSizeChanger={false}
            showLessItems
            onChange={currentGetList}
          />
        </div>
      )}
    </div>
  )
})

export default Left
