import React, { memo } from 'react'

const ToolIcon = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M4.66663 4.66667C4.66663 3.56211 5.56206 2.66667 6.66663 2.66667H9.66663C10.7712 2.66667 11.6666 3.56211 11.6666 4.66667V5.33334H4.66663V4.66667Z"
        stroke="#404040"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33337 8.33333L7.83834 9.95956C7.94451 9.98613 8.05557 9.98613 8.16174 9.95956L14.6667 8.33333V12.6667C14.6667 13.0349 14.3682 13.3333 14 13.3333H2.00004C1.63185 13.3333 1.33337 13.0349 1.33337 12.6667V8.33333Z"
        stroke="#404040"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 9V5.99999C14.6667 5.63179 14.3682 5.33333 14 5.33333H2.00004C1.63185 5.33333 1.33337 5.63179 1.33337 5.99999V9"
        stroke="#404040"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.83329 7.66666C8.83329 8.12689 8.46019 8.49999 7.99996 8.49999C7.53973 8.49999 7.16663 8.12689 7.16663 7.66666C7.16663 7.20643 7.53973 6.83333 7.99996 6.83333C8.46019 6.83333 8.83329 7.20643 8.83329 7.66666Z"
        fill="#404040"
      />
    </svg>
  )
})

export default ToolIcon
