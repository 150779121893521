import { useState } from 'react'
import antdUI from '@/antd-ui'

interface IOptsions {
  onSuccess?: (res: any) => void
  onError?: (err: any) => void
}

export function useRequest(requestFn: (...arg: any) => Promise<any>) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(false)

  const run = async (params: any, optsions?: IOptsions, config: any = {}) => {
    try {
      setLoading(true)
      const res = await requestFn(params)
      if (!res) {
        return [{}, null]
      }
      const code = config?.code ?? 200
      if (res?.code !== code) {
        if (!config.noToastCodes?.includes?.(res.code)) {
          antdUI.messageApi.open({
            type: 'error',
            content: res?.msg || '系统异常，请稍后重试',
          })
          optsions?.onError?.(res)
          setLoading(false)
          return [res, null]
        }
      }
      optsions?.onSuccess?.(res)
      setData(res)
      setLoading(false)
      return [null, res]
    } catch (error) {
      antdUI.messageApi.open({
        type: 'error',
        content: '网络异常，请稍后重试',
      })
      optsions?.onError?.(error)
      setLoading(false)
      return [error, null]
    }
  }

  return {
    run,
    loading,
    data,
  }
}
