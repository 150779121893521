import { BASE_URL } from '../request/config'
import useUserStore from '@/store/user'

/**
 * 报告生成接口
 */
export async function fetchPostStream(opstions: any) {
  // signal = new AbortController().signal  new AbortController.abort(); // 中止！
  const { data, onDownloadProgress, signal, ...args } = opstions
  const token = useUserStore.getState().token
  const ctype = 'pc_2'
  const headers: any = {
    'Content-Type': 'application/json',
    token,
    ctype,
    // 'x-channel-no': uni.getStorageSync('x-channel-no') ?? '',
  }

  const resp = await fetch(`${BASE_URL}/report/create/start`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
    signal,
  })
  const reader = resp.body?.getReader()
  const textDecoder = new TextDecoder()
  if (reader) {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read()
      // console.log('done, value: ', done, value)
      if (done) {
        break
      }
      const str = textDecoder.decode(value)
      // console.log('str: ', str)
      onDownloadProgress?.(str)
    }
  }
  return resp
}
