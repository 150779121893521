import React, { memo, FC } from 'react'
import CSS from './index.module.scss'
import Header from '@/components/Header'
import Center from '../center/center'
import Left from '../left/left'
import Right from '../right/right'

const MaxLayout: FC = memo(() => {
  return (
    <div className={CSS.main}>
      <Header />
      <div className={CSS.content}>
        <Left />
        <Center />
        <Right />
      </div>
    </div>
  )
})

export default MaxLayout
