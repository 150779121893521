import React, { memo, useRef, useEffect } from 'react'
import { Divider } from 'antd'
import CSS from './index.module.scss'
import { Markdown } from '@/components/markdown'
import classNames from 'classnames'
import { smoothScrollTo } from '@/utils'

const ContentSection = (props: any = {}) => {
  const scrollRef = useRef<any>(null)

  const scrollToBottom = () => {
    // 是否滚动
    if (scrollRef.current?._isScroll) {
      smoothScrollTo(scrollRef.current, scrollRef.current.scrollHeight + 9999)
    }
  }

  // 监听滚动事件
  const scrollHandle = (e: any) => {
    const { scrollTop, scrollHeight, offsetHeight } = e.target
    if (scrollTop < scrollHeight - offsetHeight) {
      e.target._isScroll = false
    } else {
      e.target._isScroll = true
    }
    e.target._prevScrollTop = scrollTop
  }

  useEffect(() => {
    if (scrollRef.current && scrollRef.current?._isScroll === void 0) {
      scrollRef.current._isScroll = true
      scrollRef.current._prevScrollTop = 0
    }
    scrollToBottom()
  }, [props.content])

  return (
    <div className={CSS.wrapper}>
      {props.content && (
        <>
          <Divider className={CSS.my_divider}>基于AIQ报告生成助手生成</Divider>
          <div
            ref={scrollRef}
            className={classNames([CSS.content, 'scrollbar_style'])}
            onScroll={scrollHandle}
          >
            <Markdown content={props.content} />
          </div>
          <div className={CSS.hint}>此内容由 AI 程序深度合成，非人工编辑</div>
        </>
      )}
    </div>
  )
}

export default memo(ContentSection)
