import React, { memo } from 'react'

const CollectYes = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M14.4138 7.56607L11.8384 9.753L12.6099 13.0089C12.6507 13.1792 12.639 13.3573 12.5764 13.5211C12.5137 13.6849 12.4028 13.8271 12.2575 13.9299C12.1123 14.0327 11.9391 14.0916 11.7596 14.0991C11.5801 14.1067 11.4023 14.0626 11.2485 13.9724L8.32988 12.25L5.41755 13.9724C5.2637 14.0626 5.08592 14.1067 4.90645 14.0991C4.72698 14.0916 4.55379 14.0327 4.40851 13.9299C4.26324 13.8271 4.15234 13.6849 4.08967 13.5211C4.02701 13.3573 4.01536 13.1792 4.05618 13.0089L4.82652 9.75634L2.25054 7.56607C2.11429 7.45174 2.01577 7.30081 1.96733 7.13221C1.91889 6.96362 1.92269 6.78486 1.97825 6.61835C2.0338 6.45185 2.13865 6.30501 2.27964 6.19625C2.42062 6.08749 2.59147 6.02166 2.77076 6.007L6.16619 5.72085L7.49159 2.64493C7.5608 2.48353 7.67755 2.34566 7.82713 2.24869C7.9767 2.15172 8.15243 2.09998 8.33217 2.09998C8.51191 2.09998 8.68763 2.15172 8.83721 2.24869C8.98678 2.34566 9.10353 2.48353 9.17274 2.64493L10.5021 5.72085L13.8964 6.007C14.0757 6.02166 14.2466 6.08749 14.3876 6.19625C14.5285 6.30501 14.6334 6.45185 14.6889 6.61835C14.7445 6.78486 14.7483 6.96362 14.6999 7.13221C14.6514 7.30081 14.5529 7.45174 14.4167 7.56607H14.4138Z"
        fill="#FFC24B"
      />
    </svg>
  )
})

export default CollectYes
