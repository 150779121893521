import React, { memo } from 'react'

const CloseIcon = memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <rect width="32" height="32" rx="16" fill="white" />
      <path
        d="M16.0001 14.4289L21.5001 8.92887L23.0712 10.5L17.5712 16L23.0712 21.5L21.5001 23.0711L16.0001 17.5711L10.5001 23.0711L8.92896 21.5L14.429 16L8.92896 10.5L10.5001 8.92887L16.0001 14.4289Z"
        fill="#4B7BFF"
      />
    </svg>
  )
})

export default CloseIcon
