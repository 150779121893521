import React, { memo } from 'react'

const CopyIcon2 = memo((props: any) => {
  const { color = '#4361FF' } = props
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M12.5975 1.64441C12.9459 1.64441 13.28 1.77786 13.5264 2.01541C13.7727 2.25295 13.9111 2.57513 13.9111 2.91108V10.5111C13.9111 10.847 13.7727 11.1692 13.5264 11.4067C13.28 11.6443 12.9459 11.7777 12.5975 11.7777H11.2839V13.0444C11.2839 13.3804 11.1455 13.7025 10.8992 13.9401C10.6528 14.1776 10.3187 14.3111 9.97035 14.3111H3.40245C3.05406 14.3111 2.71995 14.1776 2.47361 13.9401C2.22726 13.7025 2.08887 13.3804 2.08887 13.0444V5.44441C2.08887 5.10847 2.22726 4.78629 2.47361 4.54874C2.71995 4.31119 3.05406 4.17774 3.40245 4.17774H4.71603V2.91108C4.71603 2.57513 4.85442 2.25295 5.10077 2.01541C5.34711 1.77786 5.68122 1.64441 6.02961 1.64441H12.5975ZM9.97035 5.44441H3.40245V13.0444H9.97035V5.44441ZM6.6864 9.87774C6.86059 9.87774 7.02765 9.94447 7.15082 10.0632C7.27399 10.182 7.34319 10.3431 7.34319 10.5111C7.34319 10.679 7.27399 10.8401 7.15082 10.9589C7.02765 11.0777 6.86059 11.1444 6.6864 11.1444H5.37282C5.19863 11.1444 5.03157 11.0777 4.9084 10.9589C4.78522 10.8401 4.71603 10.679 4.71603 10.5111C4.71603 10.3431 4.78522 10.182 4.9084 10.0632C5.03157 9.94447 5.19863 9.87774 5.37282 9.87774H6.6864ZM12.5975 2.91108H6.02961V4.17774H9.97035C10.3187 4.17774 10.6528 4.31119 10.8992 4.54874C11.1455 4.78629 11.2839 5.10847 11.2839 5.44441V10.5111H12.5975V2.91108ZM7.99998 7.34441C8.16738 7.34459 8.32839 7.4064 8.45012 7.51721C8.57185 7.62803 8.6451 7.77948 8.65491 7.94063C8.66472 8.10177 8.61035 8.26045 8.50291 8.38424C8.39546 8.50802 8.24305 8.58758 8.07682 8.60664L7.99998 8.61108H5.37282C5.20542 8.6109 5.0444 8.54909 4.92267 8.43827C4.80095 8.32746 4.7277 8.17601 4.71789 8.01486C4.70807 7.85371 4.76245 7.69504 4.86989 7.57125C4.97733 7.44746 5.12974 7.36791 5.29597 7.34884L5.37282 7.34441H7.99998Z"
          fill={color}
        />
      </g>
    </svg>
  )
})

export default CopyIcon2
