import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const chatInfo: any = {
  content: '',
  reportId: null,
  type: '',
  isCollect: false,
  isInput: false,
}

const store = (set: any, get: any) => ({
  ...chatInfo,
  changeInitChatInfo() {
    // eslint-disable-next-line prettier/prettier
    set({ ...chatInfo });
  },
  changeType(type: string) {
    set({ type })
  },
  changeContent(content: string) {
    set({ content })
  },
  changeIsInput(isInput: boolean) {
    set({
      isInput,
    })
  },
  changeReportId(reportId: number | null) {
    set({
      reportId,
    })
  },
  changeIsCollect(isCollect: boolean) {
    set({
      isCollect,
    })
  },
})

function partialize(state: any) {
  // 过滤字段
  const filterArr = ['isInput', 'content', 'reportId', 'isCollect']
  return Object.fromEntries(Object.entries(state).filter(([key]) => !filterArr.includes(key)))
}

const useChatStore = create(persist(store, { name: 'chat-state', partialize }))

export default useChatStore
